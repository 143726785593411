import {
  LandingTournaments,
  Tournaments,
} from '../../../types/api/ct/tournament';
import {TournamentDetails} from '../../../types/api/ct/tournamentDetails';

import {baseURL} from '../../../utils/fetch';

import { customFetch } from '../api';

const ctUrl = `${baseURL}/ct`;

const headers = {};

type GameTypes = 'val' | 'lol';

export const getLandingDetails = async (game: GameTypes) => {
  return await customFetch(`${ctUrl}/landing/${game}/`, {
    method: 'GET',
    headers: headers,
  }).then(async (data) => {
    return await data.json();
  });
};
export const getLandingTournaments = async (game: GameTypes) => {
  return await customFetch(`${ctUrl}/landing/tournaments/${game}/`, {
    method: 'GET',
    headers: headers,
  }).then(async (data) => {
    return (await data.json()) as LandingTournaments;
  });
};
export const getRanking = async (game: GameTypes) => {
  return await customFetch(`${ctUrl}/ranking/${game}/`, {
    method: 'GET',
    headers: headers,
  }).then(async (data) => {
    return await data.json();
  });
};
export const getTeamDetails = async (teamId: string) => {
  return await customFetch(`${ctUrl}/team/${teamId}/`, {
    method: 'GET',
    headers: headers,
  }).then(async (data) => {
    return await data.json();
  });
};
export const getTournaments = async (game: GameTypes) => {
  return await customFetch(`${ctUrl}/tournaments/${game}/`, {
    method: 'GET',
    headers: headers,
  }).then(async (data) => {
    return (await data.json()) as Tournaments;
  });
};
export const getTournamentDetails = async (tournamentId: string) => {
  return await customFetch(`${ctUrl}/tournament/${tournamentId}/`, {
    method: 'GET',
    headers: headers,
  }).then(async (data) => {
    return (await data.json()) as TournamentDetails;
  });
};
