interface Props {
  championId: number;
}

const ChampionImage = ({championId}: Props) => {
  const imgUrl = `https://cdn.communitydragon.org/latest/champion/${championId}/square`;
  return imgUrl ? (
    <img height='50' width='50' src={`${encodeURI(imgUrl)}`} alt='' />
  ) : (
    <></>
  );
};

export default ChampionImage;
