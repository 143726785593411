export const EINSRoster = ['Vertigo', 'Zanzarah', "PowerOfEvil", 'Fun K3y', 'Lilipp', 'LagsAlot']

export const EINSAccounts = {
  Vertigo: [
    { "puuid": "E07fdqnRPQSBojvBkKuT2BHxSy76PEh63WEnrsSPw4Fl99jFYcVi4nfVyRlBRnFUgl0LNFupkgyMHA" },
  ],
  Zanzarah: [
    { "puuid": "5FBL9LEdYQ5RtPvzoU3ChQb8Imch5hxdKdU5PMZAih1IQo9j7CGEHJIwkwGpBLZnZj1GpayZYUTKkw" },
    { "puuid": "rP7ncf8AVWaQzwc8LTmNeOS_XrS_N2Paj9kxrzR3ztZ0pRHzV7PhUStjJeWPZKCkQfBmyCoS91L8Ig" },
    { "puuid": "wSrZD97wuK5U4cAUXLLy-JIrTcavly1qt2uw6rVJ_DNI8XyxMFEFOhnmEWSQVSBwSIadabmitrrczA" },
    { "puuid": "tZrGRY1gtRxyco3Sd9Lvi7CEmfqxih_18fwBNi0FvWPjiFaOGGYlMeuh0sYYFspDu2Cl022EECTbVQ" },
    { "puuid": "IT56wuME8IYomkJLbtP_XfD_2-zrlfhTLKbwr6Tvam8-36-E78gaG7lprkggD4eu5CQ9sUJFE0qa1g" },
  ],
  PowerOfEvil: [
    { "puuid": "SOl70-9IF-DGjEsU2N-ioMXnHRD7gJX94q70QZKoNlk0iO3UMsCQDB97TgJwXjH3wZ5IQxS1P4gb8Q" },
    { "puuid": "2-2YytPIjldIr7UoReC6wGcuXmom6HUx3clCVr9pZpPVC0igrt2eEvozaOE_EhjGH0YX95XAbQ7wag" },
  ],
  "Fun K3y": [
    { "puuid": "kfQijDS-XjWxeOnkwAYCuAKjaWH2uVUobIw1AuSgRjZshtDo9qObMFmysMAhiFqACgwW-nj5zHQPlQ" },
    { "puuid": "mR71fKXXsOVw1Cj799JsWE5obGrBdiwBIIsQgbtYcCIFDM_z9nNIdRe5Z813wJ3SBQqNKczev6gpeg" },
    { "puuid": "L0v7Kyx4xiXcT3ar7AyZXALQa-kwYr54FUkqMjX2_x_C8i4GEq_bOzJ4hPHLbL45jaE1yPIs7p3png" },
    { "puuid": "pag1L4lYKNp6D6zmSS3DWDknGRtK9Xw1mTWHhDL2mtnp0DbItDWTd8mBVx_ZCQUe64al-qjXFrL-Qw" },
    { "puuid": "eMcEFr_nqhfgxXJeVmDyFm7pBCDN0_0TKubpnO_XJdSnZ-GLj52aZ0dT_GzYUgRD14pKXulQBTUPNA" },
  ],
  Lilipp: [
    { "puuid": "m4vv9ghv2_DFE0R1VYj143e0mkCZQyYZlUdX2j4xa9o1lkKxy1k5HfsN0Mm_BQZMN7Qqeyy9ztW8JA" },
    { "puuid": "QVnNrGEbRNf1voItEriWoNf7PlMTlHVX9t0DOrBJAreMM5ouGhyQfqXysXTrLgio8XjfObPs87FUVQ" },
    { "puuid": "8yII4PQZnJkEa9kjubYDcZbCH6AohWn7KlQ8cGxw6OQRTj7TAAWau_mxfinJ5cSiZPuL9rraiQPi_g" },
    { "puuid": "7o87roAmTw2RZpy-EUdBFupMJOsonMuftNF0hAHpxSg0xseTskHMl6OdoJ6rfZJ9IvRZ4ELCOW3XWw" },
    { "puuid": "uIu5jewgaecwjh0GJkimmgR9okotnObg63QxcjmREJjGffibrJLzlGJHrBmDDdo1Tleub4o4lhxpLA" },
    { "puuid": "_hidoMLCmNq3ZtZ1b5trVDqTKJ3v4nNTOp5AamZWfYraIGRP_oCfsqdDl_spJHHcAedWBW6gDjAczQ" },
    { "puuid": "31ela80Hp5N3nx_YQ-Yvw1nT-CgtG9H1jswKXohT8ujorokeejq1e8y2f3D-Gr-06gfXMNR3UW3rMg" },
    { "puuid": "-PdENKnAbl_aXJRvHrDTdGR-BnUkpmHYIZvZoLtrsW_gkdShkOZnDs8ZzP7gRBFFkHd-KGL7KVZqZg" },
    { "puuid": "RGlsgWASsBQkRELgQcEBepKwR2RrlyKnpeRzeVENg1QW-VHd7Vrgv9OQ3DwtBwr-v03cSOKKId5v4w" },
  ],
  LagsAlot: [
    { "puuid": "hPBYuZM5jzHy23MCjCwgcostlYoaGI7NUscUPX9ekIhPvvwTKCHkzv_8zZ1wMALYO3f5cuxrgStzVw" },
  ]
}