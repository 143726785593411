import { Card, CardContent } from '@mui/material';

export const Home = () => {
  return (
    <Card sx={{ textAlign: 'center' }}>
      <CardContent>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/JiaCK_HWugI?autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen>
        </iframe>
      </CardContent>
    </Card>
  )
}
