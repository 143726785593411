interface Props {
  spellId: string;
}

const SpellImage = ({spellId}: Props) => {
  const imgUrl = `https://ddragon.leagueoflegends.com/cdn/13.9.1/img/spell/${spellId}.png`;
  return spellId ? (
    <img height='25' width='25' src={`${encodeURI(imgUrl)}`} alt='' />
  ) : (
    <></>
  );
};

export default SpellImage;
