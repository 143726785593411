import { useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Box,
  IconButton,
  Menu,
  Tooltip,
  Drawer,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { UserContext } from '../../context/UserContext';
import { TeamImage } from '../Images/TeamImage';
import { NavBarNavigation } from './NavBarNavigation';
import { Link, useLocation } from 'react-router-dom';

export const NavBar = () => {
  const location = useLocation().pathname;
  const [open, setOpen] = useState(false);

  const { user, authenticated, handleLogout } = useContext(UserContext);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutClick = () => {
    handleCloseUserMenu()
    handleLogout()
  }


  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {(location.includes('team') && authenticated) && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
            {
              location.includes('team') ? (
                <>
                  <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
                    <Button
                      variant={location === '/' ? 'contained' : 'outlined'}
                      component={Link}
                      to='/'
                    >
                      Home
                    </Button>
                  </Typography>
                  <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
                    <Button
                      variant={location.includes('/teams') ? 'contained' : 'outlined'}
                      component={Link}
                      to='/teams'
                    >
                      Team Resources
                    </Button>
                  </Typography>
                </>
              ) : (
                <>
                  <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
                    <Button
                      variant={location === '/' ? 'contained' : 'outlined'}
                      component={Link}
                      to='/'
                    >
                      Home
                    </Button>
                  </Typography>
                  <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
                    <Button
                      variant={location === '/teams' ? 'contained' : 'outlined'}
                      component={Link}
                      to='/teams'
                    >
                      Team Resources
                    </Button>
                  </Typography>
                </>
              )}
            {authenticated && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography textAlign='center' sx={{ marginRight: '16px' }}>
                  {user.nickname}
                </Typography>
                <Tooltip title='Open menu'>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <TeamImage size={50} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    onClick={handleLogoutClick}
                  >
                    <Typography textAlign='center'>Log Out</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <NavBarNavigation toggleVisibility={toggleDrawer} />
      </Drawer>
    </>
  );
};
