import { Button, Card, CardContent, CardHeader, TextField, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { postLogin } from '../../../services/api/auth';
import { UserContext } from '../../../context/UserContext';
import { UserDetails } from '../../../types/api/auth/login';
import { LoadingContext } from '../../../context/LoadingContext';

export const TeamResources = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { setIsLoading } = useContext(LoadingContext);


  const { user, setUser, authenticated, setAuthenticated } =
    useContext(UserContext);

  const validateUser = () => {
    user.nickname ? setAuthenticated(true) : setAuthenticated(false);
  };

  const handleAuth = () => {
    setIsLoading(true)
    postLogin({ username, password })
      .then((data) => {
        setIsLoading(false)
        setUser(data);
        setAuthenticated(true);
      })
      .catch((error) => {
        setIsLoading(false)
        setUser({} as UserDetails);
        setAuthenticated(false);
      });
    setUsername('');
    setPassword('');
  };

  useEffect(() => {
    validateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!authenticated &&
        <Card>
          <CardHeader title='Login' />
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <TextField
              label='Username'
              type='string'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label='Password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant={'outlined'}
              onClick={handleAuth}
              disabled={isEmpty(username) || isEmpty(password)}
            >
              Submit
            </Button>
          </CardContent>
        </Card>
      }
      <Card>
        <CardHeader title='Resources' />
        <CardContent>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid xs={1}>
              <Typography variant={'subtitle1'}>Utilities & Info</Typography>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://support-leagueoflegends.riotgames.com/hc/en-us/articles/360018987893-Patch-Schedule-League-of-Legends'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                Patches
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://docs.google.com/spreadsheets/d/1GFx7QdosSV2UFEOwwxjo_JPrLIE4uOOW2_xDwxMR1ic/'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                GSheets Utils
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://www.emea-competitiveops.com/'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                EMEA Comp-Ops
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://lolshop.gg/'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                LOL Shop
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid xs={1}>
              <Typography variant={'subtitle1'}>Replays/VODs</Typography>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://replays.xyz/old-clients'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                Old CLients
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://github.com/fraxiinus/ReplayBook'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                Replay Player
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://lol.grid.gg'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                GRID LDP
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={1}>
              <Typography variant={'subtitle1'}>Drafting</Typography>
            </Grid>
            <Grid xs={2}>
              <Button
                href='http://prodraft.leagueoflegends.com/'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                Prodraft
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://drafting.gg/draft'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                Drafting (LS)
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://draftlol.dawe.gg/'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                Draftlol
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://drafter.lol/'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                Drafter
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='http://lol.pentaq.com/BP/'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                PentaQ
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                href='https://pick-ban-gg.web.app/'
                variant='contained'
                target="_blank"
                rel="noreferrer"
              >
                Pick/Ban
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
