import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Unstable_Grid2 as Grid,
  Typography,
} from '@mui/material';

import { getChampionNameFromId, getSummonerNameFromId } from '../utils/riot';
import ChampionImage from './Images/ChampionImage';
import ItemImage from './Images/ItemImage';
import SpellImage from './Images/SpellImage';
import { GAMHSummary } from '../types/api/atlas/GAMH_Summary';

interface Props {
  match: GAMHSummary;
}

export const GAMHMatchDetails = ({ match }: Props) => {
  const matchSide = match.participants.find((participant) => {
    return participant.riotIdGameName.includes('GSMC')
  })?.teamId || 0

  const teamSide = match.teams.find((team) => team.teamId === matchSide)

  const firstTower = teamSide?.objectives.tower.first
  const towers = teamSide?.objectives.tower.kills

  const firstBaron = teamSide?.objectives.baron.first
  const barons = teamSide?.objectives.baron.kills

  const firstDragon = teamSide?.objectives.dragon.first
  const dragons = teamSide?.objectives.dragon.kills

  const firstHerald = teamSide?.objectives.riftHerald.first
  const heralds = teamSide?.objectives.riftHerald.kills

  return (
    <Card>
      <CardContent>
        <Grid container>
          {match.teams.map((team) => {
            const firstTeamCode = match.participants.find((participant) => {
              return participant.teamId === team.teamId
            })?.riotIdGameName.split(" ")[0]
            return (
              <Grid xs={6} key={firstTeamCode}>
                <Typography variant='h4'>{firstTeamCode}</Typography>
                <Grid container>
                  <Typography variant='h5'>Bans</Typography>
                  {team.bans.map((ban) => {
                    return (
                      <Grid xs={12} key={ban.championId}>
                        <CardHeader
                          style={{ padding: '0' }}
                          avatar={<ChampionImage championId={ban.championId} />}
                          title={
                            <Box>
                              <Typography>
                                {`${getChampionNameFromId(
                                  `${ban.championId}`
                                )}`}
                              </Typography>
                            </Box>
                          }
                        ></CardHeader>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container>
                  <Typography variant='h5'>Picks</Typography>
                  {match.participants.filter((participant) => participant.teamId === team.teamId).map((participant) => {
                    return (
                      <Grid xs={12} key={participant.participantId}>
                        <CardHeader
                          style={{ padding: '0' }}
                          avatar={
                            <>
                              <ChampionImage
                                championId={participant.championId}
                              />
                              <Box>
                                <Box>{participant.riotIdGameName}</Box>
                                <Box sx={{ display: 'flex' }}>
                                  <SpellImage
                                    spellId={getSummonerNameFromId(
                                      participant.spell1Id
                                    )}
                                  />
                                  <SpellImage
                                    spellId={getSummonerNameFromId(
                                      participant.spell2Id
                                    )}
                                  />
                                </Box>
                              </Box>
                            </>
                          }
                          title={
                            <Box>
                              <Typography>
                                {`${getChampionNameFromId(
                                  `${participant.championId}`
                                )}`}{' '}
                                {`${participant.kills}/${participant.deaths}/${participant.assists}`}
                              </Typography>
                              <Typography></Typography>
                              <Typography>
                                <ItemImage
                                  itemId={participant.item0}
                                  matchDate={match.gameCreation}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={participant.item1}
                                  matchDate={match.gameCreation}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={participant.item2}
                                  matchDate={match.gameCreation}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={participant.item3}
                                  matchDate={match.gameCreation}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={participant.item4}
                                  matchDate={match.gameCreation}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={participant.item5}
                                  matchDate={match.gameCreation}
                                  size={40}
                                />
                                <ItemImage
                                  itemId={participant.item6}
                                  matchDate={match.gameCreation}
                                  size={40}
                                />
                              </Typography>
                            </Box>
                          }
                        ></CardHeader>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
      <CardContent>
        <Typography variant="h4">
          Stats
        </Typography>
        <Grid container>
          <Grid xs={3}>
            First Tower : {String(firstTower)}
          </Grid>
          <Grid xs={3}>
            First baron: {String(firstBaron)}
          </Grid>
          <Grid xs={3}>
            First drake: {String(firstDragon)}
          </Grid>
          <Grid xs={3}>
            First Herald: {String(firstHerald)}
          </Grid>
          <Grid xs={3}>
            Towers: {String(towers)}
          </Grid>
          <Grid xs={3}>
            Barons: {String(barons)}
          </Grid>
          <Grid xs={3}>
            Drakes: {String(dragons)}
          </Grid>
          <Grid xs={3}>
            Heralds: {String(heralds)}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
};

