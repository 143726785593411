import TrackerNetwork from '../../assets/images/TrackerNetwork.png';

interface Props {
  riotId: string;
  size?: number;
}

export const ValorantTrackerImageLink = ({ riotId, size = 50 }: Props) => {
  const link = `https://tracker.gg/valorant/profile/riot/${encodeURIComponent(riotId)}/overview`;
  const onClick = () => {
    return window.open(link, '_blank');
  };
  return (
    <img
      height={size}
      width={size}
      src={TrackerNetwork}
      alt={link}
      onClick={() => onClick()}
      style={{ cursor: 'pointer' }}
    />
  );
};
