import Gradient from 'javascript-color-gradient';
import {ChangeEvent, useEffect, useState} from 'react';
import {
  ScatterChart,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  ZAxis,
  Cell,
} from 'recharts';
import {Button, TextField, Container, Box, Paper} from '@mui/material';

import summonersRift from '../../../assets/images/SummonersRift.webp';

import {ChartList} from '../../../types/chartList';

export const Chart = () => {
  let [initialChart, setInitialChart] = useState<ChartList[]>([
    {gameTime: 0.0, posX: 550, posY: 550},
  ]);

  let [minMins, setMinMins] = useState(0);
  let [maxMins, setMaxMins] = useState(5);
  let [mapSize, setMapSize] = useState(728);
  let [selectedMapSize, setSelectedMapSize] = useState(728);
  let [currentObject, setCurrentObject] = useState([
    {gameTime: 0, posX: 0, posY: 0},
  ]);

  useEffect(() => {
    filter(0, 5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let filter = (min: number, max: number) => {
    let newObj: {gameTime: number; posX: number; posY: number}[] = [];
    initialChart.map((event) => {
      return (
        event.gameTime >= min && event.gameTime <= max && newObj.push(event)
      );
    });
    setCurrentObject(newObj);
    colorGradient.setMidpoint(newObj.length);
  };

  const dataSetLength = currentObject.length;

  const colorGradient = new Gradient();
  colorGradient.setColorGradient('#FF0000', '#008800', '#0000FF');
  colorGradient.setMidpoint(dataSetLength);

  const genColor = (index: number) => {
    return index
      ? colorGradient.getColor(index)
      : colorGradient.getColor(0.001);
  };

  const handleChartFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    e.target.files && fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      setInitialChart(JSON.parse(e.target!.result as string) as ChartList[]);
    };
  };

  return (
    <Paper>
      <Container>
        <Box>
          <img
            src={summonersRift}
            alt="Summoner's Rift"
            width={`${selectedMapSize}`}
            height={`${selectedMapSize}`}
            style={{zIndex: '0', position: 'absolute'}}
          />
          <ScatterChart
            width={selectedMapSize}
            height={selectedMapSize}
            data={currentObject}
            style={{zIndex: '1', position: 'absolute'}}
          >
            <XAxis
              hide={true}
              type='number'
              dataKey='posX'
              domain={[0, 14500]}
            />
            <YAxis
              hide={true}
              type='number'
              dataKey='posY'
              domain={[0, 14500]}
            />
            <ZAxis type='number' dataKey='gameTime' domain={[0, 60]} />
            <Tooltip label='gameTime' />
            <Scatter data={currentObject}>
              {currentObject.map((e, i) => (
                <Cell key={i} fill={genColor(i)} />
              ))}
            </Scatter>
          </ScatterChart>
        </Box>
      </Container>
      <Container
        style={{
          display: 'flex',
          paddingTop: '750px',
        }}
      >
        <Button variant={'outlined'} component='label'>
          Upload C File
          <input
            type='file'
            accept='.json'
            onChange={handleChartFileChange}
            hidden
          />
        </Button>
        <Box display={'flex'} marginTop={'16px'}>
          <TextField
            label='From'
            type='number'
            value={minMins}
            onChange={(e) => setMinMins(Number(e.target.value))}
          />
          <TextField
            label='To'
            type='number'
            value={maxMins}
            onChange={(e) => setMaxMins(Number(e.target.value))}
          />
          <Button variant={'outlined'} onClick={() => filter(minMins, maxMins)}>
            Filter
          </Button>
        </Box>
        <Box display={'flex'} marginTop={'16px'}>
          <TextField
            label='Size (px)'
            type='number'
            value={mapSize}
            onChange={(e) => setMapSize(Number(e.target.value))}
          />
          <Button
            variant={'outlined'}
            onClick={() => setSelectedMapSize(mapSize)}
          >
            Resize
          </Button>
        </Box>
      </Container>
    </Paper>
  );
}
