import OPGGLogo from '../../assets/images/OPGG.png';

interface Props {
  summonerName: string;
  size?: number;
}

export const OPGGImageLink = ({ summonerName, size = 50 }: Props) => {
  const link = `https://www.op.gg/summoners/euw/${encodeURI(summonerName.replace('#', '-'))}`;
  const onClick = () => {
    return window.open(link, '_blank');
  };
  return (
    <img
      height={size}
      width={size}
      src={OPGGLogo}
      alt={link}
      onClick={() => onClick()}
      style={{ cursor: 'pointer' }}
    />
  );
};
