import { useState } from "react";
import { Card, CardContent, Container, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent } from "@mui/material";

import { LolCT } from "../../../components/LolCT";
import { LolSummoner } from "../../../components/LolSummoner";

export const LolScouting = () => {
  type LolCustomsType = 'CT' | 'SummonerData'
  const [type, setType] = useState<LolCustomsType>('' as LolCustomsType);

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as LolCustomsType);
  };

  return (
    <Container>
      <Paper>
        <Card>
          <CardContent>
            <FormControl sx={{marginBottom: '16px'}}>
              <InputLabel>Search Type</InputLabel>
              <Select
                value={type}
                label="Search Type"
                onChange={handleTypeChange}
                sx={{ width: 300 }}
              >
                <MenuItem value={'CT'}>CT Tournaments</MenuItem>
                <MenuItem value={'SummonerData'}>Summoner Data</MenuItem>
              </Select>
            </FormControl >
            {
              type === 'CT' && (
                <LolCT />
              )
            }
            {
              type === 'SummonerData' && (
                <LolSummoner />
              )
            }
          </CardContent>
        </Card>
      </Paper>
    </Container>
  )
}