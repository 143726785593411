import {ParsedTournaments} from '../types/api/ct/tournament';

export const preParsedTournaments: ParsedTournaments = [
  {
    name: '1ª DIV. HEXTECH SERIES LOL - SPLIT 2',
    slug: '1c2aa-div-hextech-series-lol---split-2-2',
  },
  {
    name: '2ª DIV. HEXTECH SERIES LOL - SPLIT 2',
    slug: '2c2aa-div-hextech-series-lol---split-2-2',
  },
  {
    name: '3ª DIV. HEXTECH SERIES LOL - SPLIT 2',
    slug: '3c2aa-div-hextech-series-lol---split-2-2',
  },
  {
    name: '4ª DIV. HEXTECH SERIES LOL - SPLIT 2',
    slug: '4c2aa-div-hextech-series-lol---split-2-1',
  },
  {
    name: '5ª DIV. HEXTECH SERIES LOL - SPLIT 2',
    slug: '5c2aa-div-hextech-series-lol---split-2-1',
  },
  {
    name: 'GRANADA GAMING [PRESENCIAL]',
    slug: 'granada-gaming-5bpresencial5d--1',
  },
  {
    name: 'TRIFORC3 TOURNAMENT [ Q1 ]',
    slug: 'triforc3-tournament-5b-q1-5d-1',
  },
  {
    name: 'TRIFORC3 TOURNAMENT [ Q2 ]',
    slug: 'triforc3-tournament-5b-q2-5d-1',
  },
  {
    name: 'ALICANTE DIGITAL GAMING [ Q2 LAN ]',
    slug: 'alicante-digital-gaming-5b-q2-lan-5d-1',
  },
  {
    name: 'ALICANTE DIGITAL GAMING [ Q1 LAN ]',
    slug: 'alicante-digital-gaming-5b-q1-lan-5d-1',
  },
];

export enum MongoDBs {
  lolapi = 'lolapi',
  ownapi = 'ownapi',
}

export enum OwnAPICollections {
  Teams = 'teams',
  Players = 'players',
  Accounts = 'accounts',
}

export enum RiotAPICollections {
  Matches = 'matches',
}

export type MongoCollections = OwnAPICollections & RiotAPICollections;
