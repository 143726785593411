import { toast } from "react-toastify"

export const customFetch = async (input: RequestInfo, init?: RequestInit | undefined) => {
  return await fetch(input, init).then((response) => {
    if (response.ok) {
      return response
    } else {
      toast.error(
        response.statusText || response.status,
        {
          position: "bottom-right",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
        }
      )
      throw new Error(response.statusText || `${response.status}`)
    }
  })
} 