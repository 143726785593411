import { Champions } from "../types/processing"

export const sortChamps = (a: Champions, b: Champions) => {
  if (a[Object.keys(a)[0]].games < b[Object.keys(b)[0]].games) {
    return 1
  }
  if (a[Object.keys(a)[0]].games > b[Object.keys(b)[0]].games) {
    return -1
  }
  return 0
}
