import { useEffect, useState } from 'react';
import {
  Card,
  Collapse,
  Button,
  CardHeader,
  CardContent,
  Box,
} from '@mui/material';
// import { LOGImageLink } from '../Images/LOGImage';

interface Props {
  defaultExpanded: boolean;
  keyword?: string;
  query?: string;
  title?: JSX.Element | string;
  children?: JSX.Element;
  color?: string;
}

const CollapsableCard = ({
  defaultExpanded = false,
  query,
  title,
  children,
  keyword = 'Details',
  color = '#121212',
}: Props) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(()=>{
    return setExpanded(defaultExpanded)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[children])

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        marginTop: '16px',
        marginBottom: '16px',
        width: '100%',
        backgroundColor: color
      }}
    >
      <CardHeader
        title={title || ''}
        action={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/* {query && <LOGImageLink query={query} size={36} type='match' />} */}
            <Button onClick={handleExpandClick} sx={{ marginLeft: '32px' }}>
              {expanded ? `Close ${keyword}` : `View ${keyword}`}
            </Button>
          </Box>
        }
      />
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

export default CollapsableCard;
