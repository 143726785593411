import { useEffect, useState } from "react";
import { GenericChampionStats } from "../types/processing";
import { Card, Select, MenuItem, SelectChangeEvent, CardContent, Box, Typography, Unstable_Grid2 as Grid, FormControl, InputLabel } from "@mui/material";
import ChampionImage from "./Images/ChampionImage";
import { getChampionIdFromName } from "../utils/riot";
import { sortChamps } from "../utils/stats";

interface Props {
  globalChampStats: GenericChampionStats
  blueChampStats?: GenericChampionStats
  redChampStats?: GenericChampionStats
}

type Side = "Global" | "Blue" | "Red"

export const ROFLChampionStats = ({ globalChampStats, redChampStats, blueChampStats }: Props) => {

  const [side, setSide] = useState<Side>("Global")
  const [player, setPlayer] = useState<string>('')

  const handlePlayerChange = (event: SelectChangeEvent) => {
    setPlayer(event.target.value as string);
  };
  const handleSideChange = (event: SelectChangeEvent) => {
    setSide(event.target.value as Side);
  };

  useEffect(() => {
    setPlayer(Object.keys(globalChampStats)[0])
  }, [globalChampStats])

  const champStats = side === "Blue" && blueChampStats
    ? blueChampStats
    : side === "Red" && redChampStats
      ? redChampStats
      : globalChampStats

  return (
    <>
      {champStats &&
        <Card>
          <FormControl sx={{ marginTop: '8px' }}>
            <InputLabel>Player</InputLabel>
            <Select
              value={player}
              label="Player"
              onChange={handlePlayerChange}
            >
              {Object.keys(globalChampStats).map((player) => {
                return <MenuItem key={player} value={player}>{player}</MenuItem>
              })}
            </Select>
          </FormControl >
          <FormControl sx={{ marginTop: '8px' }}>
            <InputLabel>Side</InputLabel>
            <Select
              value={side}
              label="Side"
              onChange={handleSideChange}
            >
              <MenuItem value={"Global"}>Global</MenuItem>
              {blueChampStats && <MenuItem value={"Blue"}>Blue</MenuItem>}
              {redChampStats && <MenuItem value={"Red"}>Red</MenuItem>}
            </Select>
          </FormControl >
          <CardContent>
            <Grid container>
              {champStats[player] && (
                Object.entries(champStats[player]).map(([championName, stats]) => {
                  return { [championName]: stats }
                }).sort(sortChamps).map((champion) => {
                  return Object.entries(champion).map(([championName, stats]) => {
                    return (
                      <Grid xs={4}>
                        <Box key={championName}>
                          <ChampionImage
                            championId={getChampionIdFromName(championName)}
                          />
                          <Typography>
                            {championName}
                          </Typography>
                          <Typography>
                            {`${(stats.kills / stats.games).toFixed(1)}/${(stats.deaths / stats.games).toFixed(1)}/${(stats.assists / stats.games).toFixed(1)} (${((stats.kills + stats.assists) / stats.deaths).toFixed(2)} KDA)`}
                          </Typography>
                          <Typography>
                            {`${stats.games} games /${stats.wins} wins (${Math.floor((stats.wins * 100) / stats.games)}% w/r)`}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  })
                })
              )}
            </Grid>
          </CardContent>
        </Card>
      }
    </>
  )
}