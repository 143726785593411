import { RiotAccount } from '../../../types/api/riot/accounts';
import { Match } from '../../../types/api/riot/match';
import { Summoner } from '../../../types/api/riot/summoner';

import { baseURL } from '../../../utils/fetch';

import { customFetch } from '../api';

const riotHeaders = {};

const riotUrl = `${baseURL}/riot`;

export const getMatchList = async (
  puuid: string,
  count?: number,
  start?: number,
  queue?: string,
  startTime?: number,
  endTime?: number
) => {
  const defaultCount = count || 5;

  const urlCount = `&count=${defaultCount}`;
  const urlEndTime = `&endTime=${endTime ? endTime : ''}`;
  const urlQueue = `&queue=${queue ? queue : ''}`;
  const urlStart = `start=${start ? start * defaultCount : '0'}`;
  const urlStartTime = `&startTime=${startTime ? startTime : ''}`;

  const builtUrl = `${riotUrl}/matchList/${puuid}?${urlStart}${urlCount}${urlQueue}${urlStartTime}${urlEndTime}`;
  return await customFetch(builtUrl, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json()) as string[];
  });
};

export const getMatch = async (matchId: string) => {
  return await customFetch(`${riotUrl}/match/${matchId}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json()) as Match;
  });
};

export const ingestMatch = async (matchId: string) => {
  return await customFetch(`${riotUrl}/ingest/match/${matchId}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json());
  });
};

export const ingestMatchToServerless = async (matchId: string) => {
  return await customFetch(`${riotUrl}/serverlessIngest/match/${matchId}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json());
  });
};

export const getMatchTimeline = async (matchId: string) => {
  return await customFetch(`${riotUrl}/matchTimeline/${matchId}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return await data.json();
  });
};

/**
 * @deprecated
 */
export const getSummonerBySummonerId = async (encryptedSummonerId: string) => {
  return await customFetch(`${riotUrl}/summoner/${encryptedSummonerId}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json()) as Summoner;
  });
};

/**
 * @deprecated
 */
export const getSummonerByAccountId = async (encryptedAccountId: string) => {
  return await customFetch(`${riotUrl}/summonerByAccount/${encryptedAccountId}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json()) as Summoner;
  });
};

/**
 * @deprecated
 */
export const getSummonerBySummonerName = async (summonerName: string) => {
  return await customFetch(`${riotUrl}/summonerByName/${summonerName}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json()) as Summoner;
  });
};

export const getAccountByRiotId = async (gameName: string, tagLine: string) => {
  return await customFetch(`${riotUrl}/accountByRiotId/${gameName}/${tagLine}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json()) as RiotAccount;
  });
};

export const getAccountByPuuid = async (puiid: string) => {
  return await customFetch(`${riotUrl}/accountByPuuid/${puiid}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json()) as RiotAccount;
  });
};

/**
 * @deprecated
 */
export const getSummonerByPuuid = async (encryptedPuuid: string) => {
  return await customFetch(`${riotUrl}/summonerByPuuid/${encryptedPuuid}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return (await data.json()) as Summoner;
  });
};

export const getCurrentGame = async (encryptedSummonerId: string) => {
  return await customFetch(`${riotUrl}/spectator/${encryptedSummonerId}`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return await data.json();
  });
};

export const getGameList = async () => {
  return await customFetch(`${riotUrl}/featuredGames`, {
    method: 'GET',
    headers: riotHeaders,
  }).then(async (data) => {
    return await data.json();
  });
};
