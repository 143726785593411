import { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Button,
  Autocomplete,
  TextField,
  Card,
  CardHeader,
  CardMedia,
  Typography,
  Unstable_Grid2 as Grid,
  Box,
  Pagination,
} from '@mui/material';
import ReactCountryFlag from 'react-country-flag';

import {
  Tournaments,
  LandingTournaments,
  ParsedTournaments,
  ParsedTournament,
} from '../types/api/ct/tournament';
import {
  Equipo,
  TournamentDetails,
} from '../types/api/ct/tournamentDetails';
import { RiotAccount } from '../types/api/riot/accounts';
import { Miembro, TeamDetails } from '../types/api/ct/teamDetails';

import {
  getLandingTournaments,
  getTeamDetails,
  getTournamentDetails,
  getTournaments,
} from '../services/api/ct';
import {
  getAccountByRiotId,
  getMatchList
} from '../services/api/riot';

import { preParsedTournaments } from '../utils/constants';

import CollapsableCard from './common/CollapsableCard';
import { MatchDetails } from './MatchDetails';
import CTProfileImage from './Images/CTProfileImage';
import { OPGGImageLink } from './Images/OPGGImage';
import { LOGImageLink } from './Images/LOGImage';
import { LoadingContext } from '../context/LoadingContext';
import { getNicknameByGame } from '../utils/ct';

export const LolCT = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [tournaments, setTournaments] = useState<ParsedTournaments>([]);
  const [landingTournaments, setLandingTournaments] =
    useState<ParsedTournaments>([]);
  const [tournamentOptions, setTournamentOptions] = useState<ParsedTournaments>(
    []
  );
  const [selectedTournament, setSelectedTournament] =
    useState<ParsedTournament>();
  const [selectedTournamentDetails, setSelectedTournamentDetails] =
    useState<TournamentDetails>();
  const [selectedTeam, setSelectedTeam] = useState<Equipo>();
  const [selectedTeamDetails, setSelectedTeamDetails] = useState<TeamDetails>();
  const [selectedPlayer, setSelectedPlayer] = useState<Miembro>();
  const [selectedPlayerInfo, setSelectedPlayerInfo] = useState<RiotAccount>(
    {} as RiotAccount
  );
  const [matchList, setMatchList] = useState<string[]>();

  const parseTournaments = (tournaments: Tournaments | LandingTournaments) => {
    const parsedTournaments = tournaments.map((tournament) => {
      return { name: tournament.name, slug: tournament.slug };
    });
    return parsedTournaments as ParsedTournaments;
  };

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true)
    getTournaments('lol').then((data) => {
      setTournaments(parseTournaments(data));
    }).finally(() => {
      getLandingTournaments('lol').then((data) => {
        setLandingTournaments(parseTournaments(data));
        setIsLoading(false)
      });
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isEmpty(tournaments) &&
      !isEmpty(landingTournaments) &&
      isEmpty(tournamentOptions)
    ) {
      const parsedTournaments = [
        ...preParsedTournaments,
        ...tournaments,
        ...landingTournaments,
      ];
      return setTournamentOptions(parsedTournaments);
    }
  }, [tournaments, landingTournaments, tournamentOptions]);

  useEffect(() => {
    selectedTournament
      ? getTournamentDetails(selectedTournament.slug).then((data) => {
        setSelectedTournamentDetails(data);
      })
      : setSelectedTournamentDetails(undefined);
  }, [selectedTournament]);

  const handleTournamentSelection = (
    event: any,
    newValue: ParsedTournament | null
  ) => {
    setSelectedTeam(undefined);
    setSelectedTeamDetails(undefined);
    setSelectedTournamentDetails(undefined);
    setSelectedPlayer(undefined);
    setMatchList(undefined);
    newValue
      ? setSelectedTournament(newValue)
      : setSelectedTournament(undefined);
  };

  const handleTeamSelection = (event: any, team: Equipo | null) => {
    setSelectedPlayer(undefined);
    setMatchList(undefined);
    if (team) {
      setSelectedTeam(team);
      getTeamDetails(team.slug).then((data) => {
        setSelectedTeamDetails(data);
      });
    } else {
      setSelectedTeam(undefined);
      setSelectedTeamDetails(undefined);
    }
  };

  const handleSummonerClick = (member: Miembro) => {
    setMatchList([]);
    setIsLoading(true)
    setCurrentPage(1);
    setSelectedPlayer(member);
    getAccountByRiotId(
      getNicknameByGame(member.profile.gameNicks, 'LOL').split('#')[0] as string,
      getNicknameByGame(member.profile.gameNicks, 'LOL').split('#')[1] as string
    )
      .then((data) => {
        const start = currentPage - 1
        setSelectedPlayerInfo(data);
        getMatchList(data.puuid, undefined, start, '0').then((matchListData) => {
          setMatchList(matchListData);
        });
      }).finally(() => {
        setIsLoading(false)
      });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    const start = value - 1
    setIsLoading(true)
    getMatchList(selectedPlayerInfo.puuid, undefined, start, '0')
      .then((matchListData) => {
        if (!isEmpty(matchListData)) {
          setCurrentPage(value);
          setMatchList(matchListData);
        }
      }).finally(() => {
        setIsLoading(false)
      });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Autocomplete
          disablePortal
          id='tagAutocomplete'
          options={tournamentOptions}
          getOptionLabel={(tournament) => tournament.name}
          value={selectedTournament}
          onChange={handleTournamentSelection}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label='Tournament' />
          )}
        />
        {selectedTournamentDetails &&
          selectedTournamentDetails.equipos &&
          !isEmpty(selectedTournamentDetails?.equipos) && (
            <Autocomplete
              disablePortal
              id='tagAutocomplete'
              options={[...selectedTournamentDetails.equipos]}
              getOptionLabel={(equipo) => equipo.name}
              value={selectedTeam}
              onChange={handleTeamSelection}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label='Team' />}
            />
          )}
      </Box>
      {selectedTeamDetails && (
        <>
          <Box style={{ textAlign: 'center' }}>
            <CardMedia
              component='img'
              height='150'
              width='150'
              image={`${selectedTeamDetails.equipo.logo}`}
            />
            <Typography variant='h2'>
              {selectedTeamDetails.equipo.name}
            </Typography>
          </Box>
          <Grid container>
            {selectedTeamDetails.miembros.map((member, i) => {
              return (
                <Grid xs={4} key={i}>
                  <CardHeader
                    avatar={
                      <CTProfileImage avatarUrl={member.profile.avatar} />
                    }
                    title={
                      <Box>
                        <Typography>
                          {member.username}
                          {` `}
                          <ReactCountryFlag
                            svg
                            countryCode={member.country}
                            title={member.country}
                          />
                        </Typography>
                      </Box>
                    }
                    subheader={
                      getNicknameByGame(member.profile.gameNicks, 'LOL') && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <OPGGImageLink
                            summonerName={getNicknameByGame(member.profile.gameNicks, 'LOL')}
                            size={25}
                          />
                          <LOGImageLink
                            query={`${getNicknameByGame(member.profile.gameNicks, 'LOL')}`}
                            size={20}
                            type='player'
                          />
                          <Button onClick={() => handleSummonerClick(member)} variant='outlined'
                            sx={{ marginLeft: '8px' }}
                          >
                            <Typography>
                              {getNicknameByGame(member.profile.gameNicks, 'LOL')}
                            </Typography>
                          </Button>
                        </Box>
                      )
                    }
                  ></CardHeader>
                </Grid>
              );
            })}
          </Grid>
          {!isEmpty(matchList) && matchList && selectedPlayer && (
            <Card style={{ textAlign: 'center' }}>
              <CardHeader title={selectedPlayer.username}></CardHeader>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {matchList.map((match, i) => {
                  return (
                    <CollapsableCard
                      defaultExpanded={false}
                      title={match}
                      query={match}
                      key={i}
                    >
                      <MatchDetails match={match} />
                    </CollapsableCard>
                  );
                })}
                <Pagination
                  color='primary'
                  count={10}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Box>
            </Card>
          )}
        </>
      )}
    </Box>
  );
};
