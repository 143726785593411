import BaronIcon from '../../assets/images/League/Baron.svg';
import HeraldIcon from '../../assets/images/League/Herald.svg';
import ChampionIcon from '../../assets/images/League/Champion.png';
import TowerIcon from '../../assets/images/League/Tower.png';
import InhibitorIcon from '../../assets/images/League/Inhibitor.png';
import DragonIcon from '../../assets/images/League/Dragon.png';


type Icon = 'Baron' | 'Herald' | 'Champion' | 'Tower' | 'Inhibitor' | 'Dragon'

interface Props {
  icon: Icon;
  size?: number;
}

const iconMapping = {
  Baron: BaronIcon,
  Herald: HeraldIcon,
  Champion: ChampionIcon,
  Tower: TowerIcon,
  Inhibitor: InhibitorIcon,
  Dragon: DragonIcon,
};

export const StatIconImage = ({icon, size = 150 }: Props) => {
  const image = iconMapping[icon]
  return (
    <img height={size} width={size} src={image} alt={`${image} icon`} />
  );
};
