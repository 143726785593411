import { saveAs } from 'file-saver'

import { SeriesListing, SeriesResponse } from '../../../types/api/grid/grid';
import { baseURL, baseHeaders } from '../../../utils/fetch';

import { customFetch } from '../api';

const gridUrl = `${baseURL}/grid`;

export const getEsports = async (cursors: { startCursor?: string; endCursor?: string }) => {
  const parsedQueries = `?${cursors.startCursor ? `after=${cursors.startCursor}&` : ''}${cursors.endCursor ? `before=${cursors.endCursor}` : ''}`
  return await customFetch(`${gridUrl}/esports/${parsedQueries}`, {
    method: 'GET',
    headers: baseHeaders,
  }).then(async (data) => {
    return (await data.json()) as SeriesResponse;
  });
};

export const getScrims = async (cursors: { startCursor?: string; endCursor?: string }) => {
  const parsedQueries = `?${cursors.startCursor ? `after=${cursors.startCursor}&` : ''}${cursors.endCursor ? `before=${cursors.endCursor}` : ''}`
  return await customFetch(`${gridUrl}/scrims/${parsedQueries}`, {
    method: 'GET',
    headers: baseHeaders,
  }).then(async (data) => {
    return (await data.json()) as SeriesResponse;
  });
};

export const getDownload = async (seriesId: string) => {
  return await customFetch(`${gridUrl}/listing/${seriesId}/`, {
    method: 'GET',
    headers: baseHeaders,
  }).then(async (data) => {
    return (await data.json()) as SeriesListing;
  });
};

export const postDownload = async (url: string) => {
  return await customFetch(`${gridUrl}/download/`, {
    method: 'POST',
    headers: { ...baseHeaders },
    body: JSON.stringify({
      'url': url
    }),
  }).then(async (data) => {
    const fileName = (data.headers.get("Content-Disposition") as string).replaceAll('"', '').split("attachment; filename=")[1]
    const file = await data.arrayBuffer().then((buffer) => {
      return buffer
    })
    return (saveAs(new Blob([file]), fileName))
  })
}

export const ingestScrimSummary = async (seriesId: string) => {
  return await customFetch(`${gridUrl}/ingest/scrim/${seriesId}/`, {
    method: 'GET',
    headers: baseHeaders,
  })
};
