import { flatten, groupBy, isEmpty } from "lodash"
import { useContext, useEffect, useState } from "react"
import { Box, Card, Container, Paper, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material"

import { getMVGRoflScrimList } from "../../../services/api/atlas"
import CollapsableCard from "../../../components/common/CollapsableCard"
import { ROFLSummaryList } from "../../../types/api/atlas/ROFL Summary"
import { ROFLMatchDetails } from "../../../components/ROFLMatchDetails"
import { ROFLChampionStats } from "../../../components/ROFLChampionStats"
import { GenericChampionStats } from "../../../types/processing"
import { LoadingContext } from "../../../context/LoadingContext"

export const MVGReplayScrimsDashboard = () => {
  const [patch, setPatch] = useState<string>('14.19');
  const [scrimList, setScrimList] = useState<ROFLSummaryList>([])
  const [globalChampStats, setGlobalChampStats] = useState<GenericChampionStats>()
  const [blueChampStats, setBlueChampStats] = useState<GenericChampionStats>()
  const [redChampStats, setRedChampStats] = useState<GenericChampionStats>()

  const patches = ['All', "14.16", "14.17", "14.18", "14.19", "14.20", "14.21", "14.22"]

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true)
    getMVGRoflScrimList(patch).then((data) => {
      setIsLoading(false)
      setScrimList(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getMVGRoflScrimList(patch).then((data) => {
      setIsLoading(false)
      setScrimList(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patch])

  useEffect(() => {
    const [globalChampStats, blueChampStats, redChampStats] = procChampStats()
    setGlobalChampStats(globalChampStats)
    setBlueChampStats(blueChampStats)
    setRedChampStats(redChampStats)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrimList])


  const handlePatchChange = (event: SelectChangeEvent) => {
    setPatch(event.target.value)
  }

  const procChampStats = () => {
    const mergedPositions = groupBy(
      flatten(
        scrimList.map((scrim) => {
          return (scrim.statsJson.filter((player) => {
            return player.name.includes('MVG')
          }))
        })
      ), "name"
    )

    const championsByPosition = Object.entries(mergedPositions).map(([playerName, playerPicks]) => {
      const proc = groupBy(playerPicks, "skin")
      return { [playerName]: proc }
    })

    const globalChampionStatsByP = flatten(
      championsByPosition.map((player) => {
        return Object.entries(player).map(([playerName, playerPicks]) => {
          const ret = Object.entries(playerPicks).map(([championName, championGames]) => {
            const championStats = {
              [championName]: {
                kills: 0,
                deaths: 0,
                assists: 0,
                games: 0,
                wins: 0
              }
            }
            // eslint-disable-next-line array-callback-return
            championGames.map((match) => {
              championStats[championName].kills += Number(match.championsKilled)
              championStats[championName].deaths += Number(match.numDeaths)
              championStats[championName].assists += Number(match.assists)
              championStats[championName].games += 1
              if (match.win === 'Win') {
                championStats[championName].wins += 1
              }
            })
            return championStats
          }
          )
          return { [playerName]: Object.assign({}, ...ret) }
        })
      })
    )

    const blueChampionStatsByP = flatten(
      championsByPosition.map((player) => {
        return Object.entries(player).map(([playerName, playerPicks]) => {
          // eslint-disable-next-line array-callback-return
          const ret = Object.entries(playerPicks).map(([championName, championGames]) => {
            const championStats = {
              [championName]: {
                kills: 0,
                deaths: 0,
                assists: 0,
                games: 0,
                wins: 0
              }
            }
            // eslint-disable-next-line array-callback-return
            championGames.filter((participant) => participant.team === '100').map((match) => {
              championStats[championName].kills += Number(match.championsKilled)
              championStats[championName].deaths += Number(match.numDeaths)
              championStats[championName].assists += Number(match.assists)
              championStats[championName].games += 1
              if (match.win === 'Win') {
                championStats[championName].wins += 1
              }
            })
            if (championStats[championName].games > 0) {
              return championStats
            }
          }
          ).filter((element) => element !== undefined)
          return { [playerName]: Object.assign({}, ...ret) }
        })
      })
    )

    const redChampionStatsByP = flatten(
      championsByPosition.map((player) => {
        return Object.entries(player).map(([playerName, playerPicks]) => {
          // eslint-disable-next-line array-callback-return
          const ret = Object.entries(playerPicks).map(([championName, championGames]) => {
            const championStats = {
              [championName]: {
                kills: 0,
                deaths: 0,
                assists: 0,
                games: 0,
                wins: 0
              }
            }
            // eslint-disable-next-line array-callback-return
            championGames.filter((participant) => participant.team === '200').map((match) => {
              championStats[championName].kills += Number(match.championsKilled)
              championStats[championName].deaths += Number(match.numDeaths)
              championStats[championName].assists += Number(match.assists)
              championStats[championName].games += 1
              if (match.win === 'Win') {
                championStats[championName].wins += 1
              }
            })
            if (championStats[championName].games > 0) {
              return championStats
            }
          }
          ).filter((element) => element !== undefined)
          return { [playerName]: Object.assign({}, ...ret) }
        })
      })
    )
    return [Object.assign({}, ...globalChampionStatsByP), Object.assign({}, ...blueChampionStatsByP), Object.assign({}, ...redChampionStatsByP)]
  }

  return (
    <Container>
      <Paper>
        <Card>
          <Box sx={{ margin: '16px' }}>
            <FormControl sx={{ marginTop: '16px', marginBottom: '16px', minWidth: '160px' }} >
              <InputLabel>Patch</InputLabel>
              <Select
                value={patch}
                label="Patch"
                onChange={handlePatchChange}
                fullWidth
              >
                {patches.map((patch) => {
                  return <MenuItem key={patch} value={patch}>{patch}</MenuItem>
                })}
              </Select>
            </FormControl >
          </Box>
        </Card>
        {!isEmpty(globalChampStats)
          && globalChampStats
          && blueChampStats
          && redChampStats
          && (
            <CollapsableCard
              defaultExpanded={false}
              keyword="Champion Stats"
              title={'Champion Stats'}
            >
              <ROFLChampionStats
                globalChampStats={globalChampStats}
                blueChampStats={blueChampStats}
                redChampStats={redChampStats}
              />
            </CollapsableCard>
          )}
        {!isEmpty(scrimList) && (
          <CollapsableCard
            defaultExpanded={false}
            keyword="Scrims"
            title={'Scrim List'}
          >
            <>
              {scrimList.map((scrim, i) => {
                const ownTeamId = scrim.statsJson.find((participant) => {
                  return participant.name.includes('MVG')
                })?.team
                const isWin = scrim.gameWinner.toString() === ownTeamId
                const copyGameId = () => {
                  navigator.clipboard.writeText(`${scrim.fileName.split('.')[0]}`)
                }
                return (
                  <CollapsableCard
                    defaultExpanded={false}
                    keyword="Scrim"
                    title={
                      (
                        <Box onClick={copyGameId}>
                          {`${scrim.gameVersion.slice(0, 5)} - ${scrim.fileName.split('.')[0]}`}
                        </Box>
                      )
                    }
                    key={i}
                    color={isWin ? '#202a3e' : '#472a2f'}
                  >
                    <ROFLMatchDetails match={scrim} teamCode={'MVG'} />
                  </CollapsableCard>
                );
              })}
            </>
          </CollapsableCard>
        )
        }
      </Paper>
    </Container >
  )
} 