import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material';

import { AppRoutes } from './AppRoutes';
import { UserProvider } from './context/UserContext';
import { LoadingProvider } from './context/LoadingContext';

import './App.css';

const App = () => {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <div className='App'>
      <BrowserRouter>
        <ThemeProvider theme={darkTheme}>
          <UserProvider>
            <LoadingProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AppRoutes />
              </LocalizationProvider>
            </LoadingProvider>
          </UserProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
