import { isEmpty } from "lodash"
import { useContext, useEffect, useState } from "react"
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts"
import { Box, Card, Container, Unstable_Grid2 as Grid, Paper, Typography } from "@mui/material"

import { getGSMCScrimList } from "../../../services/api/atlas"
import { GAMHSummaryList } from "../../../types/api/atlas/GAMH_Summary"
import CollapsableCard from "../../../components/common/CollapsableCard"
import { GAMHMatchDetails } from "../../../components/GAMHMatchDetails"
import { StatIconImage } from "../../../components/Images/StatIconImage"
import { LoadingContext } from "../../../context/LoadingContext"

export const GSMCScrimsDashboard = () => {
  const timeSelections = {
    gllSpring2024: {
      split: 'GLL Spring 2024',
      startTime: 1704070861000,
      endTime: 1711933261000
    },
    gllWinter2023: {
      split: 'GLL Winter 2023',
      startTime: 1695517261000,
      endTime: 1704027549000
    }
    ,
    gllSummer2024: {
      split: 'GLL Summer 2024',
      startTime: 1714521600,
      endTime: 1725148800
    }
  }

  const [scrimList, setScrimList] = useState<GAMHSummaryList>([])
  const [startTime] = useState<number>(timeSelections.gllWinter2023.startTime)
  const [endTime] = useState<number>(timeSelections.gllWinter2023.endTime)

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true)
    getGSMCScrimList(startTime, endTime).then((data) => {
      setIsLoading(false)
      setScrimList(data)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime, startTime])
  //100
  const bsGames = scrimList.filter((scrim) => {
    return scrim.participants.find((participant) => {
      return participant.riotIdGameName.includes('GSMC') && participant.teamId === 100
    })
  })

  const bsWins = bsGames.filter((filteredScrim) => {
    return filteredScrim.participants.find((participant) => {
      return participant.riotIdGameName.includes('GSMC') && participant.nexusLost === 0
    }) || filteredScrim.teams.find((team) => {
      return team.teamId === 100 && !!team.win
    })
  })

  const rsGames = scrimList.filter((scrim) => {
    return scrim.participants.find((participant) => {
      return participant.riotIdGameName.includes('GSMC') && participant.teamId === 200
    })
  })
  
  const rsWins = rsGames.filter((filteredScrim) => {
    return filteredScrim.participants.find((participant) => {
      return participant.riotIdGameName.includes('GSMC') && participant.nexusLost === 0
    }) || filteredScrim.teams.find((team) => {
      return team.teamId === 200 && !!team.win
    })
  })

  const bsObjectives = {
    baron: {
      first: 0,
      kills: 0
    },
    champion: {
      first: 0,
      kills: 0
    },
    dragon: {
      first: 0,
      kills: 0
    },
    inhibitor: {
      first: 0,
      kills: 0
    },
    riftHerald: {
      first: 0,
      kills: 0
    },
    tower: {
      first: 0,
      kills: 0
    }
  }

  const rsObjectives = {
    baron: {
      first: 0,
      kills: 0
    },
    champion: {
      first: 0,
      kills: 0
    },
    dragon: {
      first: 0,
      kills: 0
    },
    inhibitor: {
      first: 0,
      kills: 0
    },
    riftHerald: {
      first: 0,
      kills: 0
    },
    tower: {
      first: 0,
      kills: 0
    }
  }

  bsGames.map((bsGame) => {
    return bsGame.teams[0].objectives
    // eslint-disable-next-line array-callback-return
  }).map((objective) => {
    objective.baron.first && (bsObjectives.baron.first += 1)
    bsObjectives.baron.kills += objective.baron.kills

    objective.champion.first && (bsObjectives.champion.first += 1)
    bsObjectives.champion.kills += objective.champion.kills

    objective.dragon.first && (bsObjectives.dragon.first += 1)
    bsObjectives.dragon.kills += objective.dragon.kills

    objective.inhibitor.first && (bsObjectives.inhibitor.first += 1)
    bsObjectives.inhibitor.kills += objective.inhibitor.kills

    objective.riftHerald.first && (bsObjectives.riftHerald.first += 1)
    bsObjectives.riftHerald.kills += objective.riftHerald.kills

    objective.tower.first && (bsObjectives.tower.first += 1)
    bsObjectives.tower.kills += objective.tower.kills
  })

  rsGames.map((rsGame) => {
    return rsGame.teams[1].objectives
    // eslint-disable-next-line array-callback-return
  }).map((objective) => {
    objective.baron.first && (rsObjectives.baron.first += 1)
    rsObjectives.baron.kills += objective.baron.kills

    objective.champion.first && (rsObjectives.champion.first += 1)
    rsObjectives.champion.kills += objective.champion.kills

    objective.dragon.first && (rsObjectives.dragon.first += 1)
    rsObjectives.dragon.kills += objective.dragon.kills

    objective.inhibitor.first && (rsObjectives.inhibitor.first += 1)
    rsObjectives.inhibitor.kills += objective.inhibitor.kills

    objective.riftHerald.first && (rsObjectives.riftHerald.first += 1)
    rsObjectives.riftHerald.kills += objective.riftHerald.kills

    objective.tower.first && (rsObjectives.tower.first += 1)
    rsObjectives.tower.kills += objective.tower.kills
  })

  const combinedObjectives = {
    baron: {
      first: bsObjectives.baron.first + rsObjectives.baron.first,
      kills: bsObjectives.baron.kills + rsObjectives.baron.kills
    },
    champion: {
      first: bsObjectives.champion.first + rsObjectives.champion.first,
      kills: bsObjectives.champion.kills + rsObjectives.champion.kills
    },
    dragon: {
      first: bsObjectives.dragon.first + rsObjectives.dragon.first,
      kills: bsObjectives.dragon.kills + rsObjectives.dragon.kills
    },
    inhibitor: {
      first: bsObjectives.inhibitor.first + rsObjectives.inhibitor.first,
      kills: bsObjectives.inhibitor.kills + rsObjectives.inhibitor.kills
    },
    riftHerald: {
      first: bsObjectives.riftHerald.first + rsObjectives.riftHerald.first,
      kills: bsObjectives.riftHerald.kills + rsObjectives.riftHerald.first
    },
    tower: {
      first: bsObjectives.tower.first + rsObjectives.tower.first,
      kills: bsObjectives.tower.kills + rsObjectives.tower.kills
    }
  }

  const gameStats = {
    blueSideGames: bsGames.length,
    blueSideGamesWon: bsWins.length,
    blueSideObjectives: bsObjectives,
    redSideGames: rsGames.length,
    redSideGamesWon: rsWins.length,
    redSideObjectives: rsObjectives,
    combinedGames: bsGames.length + rsGames.length,
    combinedWins: bsWins.length + rsWins.length,
    combinedObjectives: combinedObjectives
  }

  const parsedBSStats = [
    {
      'name': 'Blue side Wins',
      'value': gameStats.blueSideGamesWon
    },
    {
      'name': 'Blue side Defeats',
      'value': gameStats.blueSideGames - gameStats.blueSideGamesWon
    },
  ]

  const parsedRSStats = [
    {
      'name': 'Red side Wins',
      'value': gameStats.redSideGamesWon
    },
    {
      'name': 'Red side Defeats',
      'value': gameStats.redSideGames - gameStats.redSideGamesWon
    },
  ]

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number, name: string, value: number }) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, name, value } = props
    const radius = innerRadius + (outerRadius - innerRadius) * 0.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}% ${name.split(' ')[2]} (${value})`}
      </text>
    );
  };

  return (
    <Container>
      <Paper>
        <Card>
          <Box sx={{ margin: '16px' }}>
            <Box>
              <Typography variant="h5" color={"white"}>
                Scrims Stats ({scrimList.length} scrims tracked)
              </Typography>
            </Box>
            <Grid container>
              <Grid xs={6} sx={{ paddingRight: '16px', backgroundColor: '#202a3e' }}>
                <ResponsiveContainer width="100%" minHeight="300px">
                  <>
                    <Typography>
                      Blue Side ({gameStats.blueSideGames})
                    </Typography>
                    <PieChart width={250} height={250}>
                      <Pie data={parsedBSStats} labelLine={false} label={renderCustomizedLabel} dataKey="value" cx="50%" cy="50%">
                        {parsedBSStats.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.name.includes('Win') ? '#246840' : '#660A10'} />
                        ))}
                      </Pie>
                    </PieChart>
                    <Typography variant="h5">
                      Blue Side Objectives
                    </Typography>
                    <Grid container>
                      {Object.entries(gameStats.blueSideObjectives).map(([key, value]) => {
                        return (
                          <>
                            <Grid xs={6} sx={{ marginBottom: '8px' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {key.includes('Herald') && <StatIconImage icon={'Herald'} size={16} />}
                                {key.includes('baron') && <StatIconImage icon={'Baron'} size={16} />}
                                {key.includes('champion') && <StatIconImage icon={'Champion'} size={18} />}
                                {key.includes('inhibitor') && <StatIconImage icon={'Inhibitor'} size={18} />}
                                {key.includes('dragon') && <StatIconImage icon={'Dragon'} size={18} />}
                                {key.includes('tower') && <StatIconImage icon={'Tower'} size={18} />}
                                {`First ${key}: ${value.first} (${((100 * value.first) / bsGames.length).toFixed(1)}%)`}
                              </Box>
                            </Grid>
                            <Grid xs={6} sx={{ marginBottom: '8px' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {key.includes('Herald') && <StatIconImage icon={'Herald'} size={16} />}
                                {key.includes('baron') && <StatIconImage icon={'Baron'} size={16} />}
                                {key.includes('champion') && <StatIconImage icon={'Champion'} size={18} />}
                                {key.includes('inhibitor') && <StatIconImage icon={'Inhibitor'} size={18} />}
                                {key.includes('dragon') && <StatIconImage icon={'Dragon'} size={18} />}
                                {key.includes('tower') && <StatIconImage icon={'Tower'} size={18} />}
                                {`${key.charAt(0).toUpperCase() + key.slice(1)} kills: ${value.kills} (${(value.kills / bsGames.length).toFixed(1)} per game)`}
                              </Box>
                            </Grid>
                          </>
                        )
                      })}
                    </Grid>
                  </>
                </ResponsiveContainer>
              </Grid>
              <Grid xs={6} sx={{ paddingLeft: '16px', backgroundColor: '#472a2f' }}>
                <ResponsiveContainer width="100%" minHeight="300px">
                  <>
                    <Typography>
                      Red Side ({gameStats.redSideGames})
                    </Typography>
                    <PieChart width={250} height={250}>
                      <Pie data={parsedRSStats} labelLine={false} label={renderCustomizedLabel} dataKey="value" cx="50%" cy="50%">
                        {parsedRSStats.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.name.includes('Win') ? '#246840' : '#660A10'} />
                        ))}
                      </Pie>
                    </PieChart>
                    <Typography variant="h5">
                      Red Side Objectives
                    </Typography>
                    <Grid container>
                      {Object.entries(gameStats.redSideObjectives).map(([key, value]) => {
                        return (
                          <>
                            <Grid xs={6} sx={{ marginBottom: '8px' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {key.includes('Herald') && <StatIconImage icon={'Herald'} size={16} />}
                                {key.includes('baron') && <StatIconImage icon={'Baron'} size={16} />}
                                {key.includes('champion') && <StatIconImage icon={'Champion'} size={18} />}
                                {key.includes('inhibitor') && <StatIconImage icon={'Inhibitor'} size={18} />}
                                {key.includes('dragon') && <StatIconImage icon={'Dragon'} size={18} />}
                                {key.includes('tower') && <StatIconImage icon={'Tower'} size={18} />}
                                {`First ${key}: ${value.first} (${((100 * value.first) / rsGames.length).toFixed(1)}%)`}
                              </Box>
                            </Grid>
                            <Grid xs={6} sx={{ marginBottom: '8px' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {key.includes('Herald') && <StatIconImage icon={'Herald'} size={16} />}
                                {key.includes('baron') && <StatIconImage icon={'Baron'} size={16} />}
                                {key.includes('champion') && <StatIconImage icon={'Champion'} size={18} />}
                                {key.includes('inhibitor') && <StatIconImage icon={'Inhibitor'} size={18} />}
                                {key.includes('dragon') && <StatIconImage icon={'Dragon'} size={18} />}
                                {key.includes('tower') && <StatIconImage icon={'Tower'} size={18} />}
                                {`${key.charAt(0).toUpperCase() + key.slice(1)} kills: ${value.kills} (${(value.kills / rsGames.length).toFixed(1)} per game)`}
                              </Box>
                            </Grid>
                          </>
                        )
                      })}
                    </Grid>
                  </>
                </ResponsiveContainer>
              </Grid>
              <Grid xs={12} sx={{ marginTop: '32px' }}>
                <Typography variant="h5">
                  Overall Objectives
                </Typography>
                <Grid container>
                  {Object.entries(gameStats.combinedObjectives).map(([key, value]) => {
                    return (
                      <>
                        <Grid xs={3} sx={{ marginBottom: '8px' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {key.includes('Herald') && <StatIconImage icon={'Herald'} size={16} />}
                            {key.includes('baron') && <StatIconImage icon={'Baron'} size={16} />}
                            {key.includes('champion') && <StatIconImage icon={'Champion'} size={18} />}
                            {key.includes('inhibitor') && <StatIconImage icon={'Inhibitor'} size={18} />}
                            {key.includes('dragon') && <StatIconImage icon={'Dragon'} size={18} />}
                            {key.includes('tower') && <StatIconImage icon={'Tower'} size={18} />}
                            {`First ${key}: ${value.first} (${((100 * value.first) / (bsGames.length + rsGames.length)).toFixed(1)}%)`}
                          </Box>
                        </Grid>
                        <Grid xs={3} sx={{ marginBottom: '8px' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {key.includes('Herald') && <StatIconImage icon={'Herald'} size={16} />}
                            {key.includes('baron') && <StatIconImage icon={'Baron'} size={16} />}
                            {key.includes('champion') && <StatIconImage icon={'Champion'} size={18} />}
                            {key.includes('inhibitor') && <StatIconImage icon={'Inhibitor'} size={18} />}
                            {key.includes('dragon') && <StatIconImage icon={'Dragon'} size={18} />}
                            {key.includes('tower') && <StatIconImage icon={'Tower'} size={18} />}
                            {`${key.charAt(0).toUpperCase() + key.slice(1)} kills: ${value.kills} (${(value.kills / (bsGames.length + rsGames.length)).toFixed(1)} per game)`}
                          </Box>
                        </Grid>
                      </>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
        {!isEmpty(scrimList) && (
          <CollapsableCard
            defaultExpanded={false}
            keyword="Scrims"
            title={'Scrim List'}
          >
            <>
              {scrimList.map((scrim, i) => {
                const ownTeamId = scrim.participants.find((participant) => {
                  return participant.riotIdGameName.includes('GSMC')
                })?.teamId
                const isWin = scrim.teams.find((team) => team.teamId === ownTeamId)?.win
                const copyGameId = () => {
                  navigator.clipboard.writeText(`${scrim.platformId}_${scrim.gameId}`)
                }
                return (
                  <CollapsableCard
                    defaultExpanded={false}
                    keyword="Scrim"
                    title={
                      (
                        <Box onClick={copyGameId}>{`${new Date(
                          scrim.gameStartTimestamp
                        ).toLocaleDateString()} - ${scrim.gameVersion.slice(0, 5)} - ${scrim.gameName}`}
                        </Box>
                      )
                    }
                    key={i}
                    color={isWin ? '#202a3e' : '#472a2f'}
                  >
                    <GAMHMatchDetails match={scrim} />
                  </CollapsableCard>
                );
              })}
            </>
          </CollapsableCard>
        )
        }
      </Paper>
    </Container >
  )
} 