import { flatten, groupBy, isEmpty } from "lodash"
import { useContext, useEffect, useState } from "react"
import { Box, Card, Container, Paper, Typography, Unstable_Grid2 as Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material"

import { getGSMCRoflScrimList } from "../../../services/api/atlas"
import CollapsableCard from "../../../components/common/CollapsableCard"
import { ROFLSummaryList } from "../../../types/api/atlas/ROFL Summary"
import { ROFLMatchDetails } from "../../../components/ROFLMatchDetails"
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts"
import { ROFLChampionStats } from "../../../components/ROFLChampionStats"
import { GenericChampionStats } from "../../../types/processing"
import { LoadingContext } from "../../../context/LoadingContext"

export const GSMCRoflScrimsDashboard = () => {
  const [patch, setPatch] = useState<string>('14.13');
  const [scrimList, setScrimList] = useState<ROFLSummaryList>([])
  const [globalChampStats, setGlobalChampStats] = useState<GenericChampionStats>()
  const [blueChampStats, setBlueChampStats] = useState<GenericChampionStats>()
  const [redChampStats, setRedChampStats] = useState<GenericChampionStats>()

  const patches = ["14.9", "14.10", "14.11", "14.12", "14.13", "14.14"]

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true)
    getGSMCRoflScrimList(patch).then((data) => {
      setIsLoading(false)
      setScrimList(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getGSMCRoflScrimList(patch).then((data) => {
      setIsLoading(false)
      setScrimList(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patch])

  useEffect(() => {
    const [globalChampStats, blueChampStats, redChampStats] = procChampStats()
    setGlobalChampStats(globalChampStats)
    setBlueChampStats(blueChampStats)
    setRedChampStats(redChampStats)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrimList])


  const handlePatchChange = (event: SelectChangeEvent) => {
    setPatch(event.target.value)
  }

  const procChampStats = () => {
    const mergedPositions = groupBy(
      flatten(
        scrimList.map((scrim) => {
          return (scrim.statsJson.filter((player) => {
            return player.name.includes('GSMC')
          }))
        })
      ), "name"
    )

    const championsByPosition = Object.entries(mergedPositions).map(([playerName, playerPicks]) => {
      const proc = groupBy(playerPicks, "skin")
      return { [playerName]: proc }
    })

    const globalChampionStatsByP = flatten(
      championsByPosition.map((player) => {
        return Object.entries(player).map(([playerName, playerPicks]) => {
          const ret = Object.entries(playerPicks).map(([championName, championGames]) => {
            const championStats = {
              [championName]: {
                kills: 0,
                deaths: 0,
                assists: 0,
                games: 0,
                wins: 0
              }
            }
            // eslint-disable-next-line array-callback-return
            championGames.map((match) => {
              championStats[championName].kills += Number(match.championsKilled)
              championStats[championName].deaths += Number(match.numDeaths)
              championStats[championName].assists += Number(match.assists)
              championStats[championName].games += 1
              if (match.win === 'Win') {
                championStats[championName].wins += 1
              }
            })
            return championStats
          }
          )
          return { [playerName]: Object.assign({}, ...ret) }
        })
      })
    )

    const blueChampionStatsByP = flatten(
      championsByPosition.map((player) => {
        return Object.entries(player).map(([playerName, playerPicks]) => {
          // eslint-disable-next-line array-callback-return
          const ret = Object.entries(playerPicks).map(([championName, championGames]) => {
            const championStats = {
              [championName]: {
                kills: 0,
                deaths: 0,
                assists: 0,
                games: 0,
                wins: 0
              }
            }
            // eslint-disable-next-line array-callback-return
            championGames.filter((participant) => participant.team === '100').map((match) => {
              championStats[championName].kills += Number(match.championsKilled)
              championStats[championName].deaths += Number(match.numDeaths)
              championStats[championName].assists += Number(match.assists)
              championStats[championName].games += 1
              if (match.win === 'Win') {
                championStats[championName].wins += 1
              }
            })
            if (championStats[championName].games > 0) {
              return championStats
            }
          }
          ).filter((element) => element !== undefined)
          return { [playerName]: Object.assign({}, ...ret) }
        })
      })
    )

    const redChampionStatsByP = flatten(
      championsByPosition.map((player) => {
        return Object.entries(player).map(([playerName, playerPicks]) => {
          // eslint-disable-next-line array-callback-return
          const ret = Object.entries(playerPicks).map(([championName, championGames]) => {
            const championStats = {
              [championName]: {
                kills: 0,
                deaths: 0,
                assists: 0,
                games: 0,
                wins: 0
              }
            }
            // eslint-disable-next-line array-callback-return
            championGames.filter((participant) => participant.team === '200').map((match) => {
              championStats[championName].kills += Number(match.championsKilled)
              championStats[championName].deaths += Number(match.numDeaths)
              championStats[championName].assists += Number(match.assists)
              championStats[championName].games += 1
              if (match.win === 'Win') {
                championStats[championName].wins += 1
              }
            })
            if (championStats[championName].games > 0) {
              return championStats
            }
          }
          ).filter((element) => element !== undefined)
          return { [playerName]: Object.assign({}, ...ret) }
        })
      })
    )
    return [Object.assign({}, ...globalChampionStatsByP), Object.assign({}, ...blueChampionStatsByP), Object.assign({}, ...redChampionStatsByP)]
  }

  //100
  const bsGames = scrimList.filter((scrim) => {
    return scrim.statsJson.find((participant) => {
      return participant.name.includes('GSMC') && Number(participant.team) === 100
    })
  })

  const bsWins = bsGames.filter((filteredScrim) => {
    return filteredScrim.statsJson.find((participant) => {
      return participant.name.includes('GSMC') && participant.win === 'Win'
    })
  })

  //200
  const rsGames = scrimList.filter((scrim) => {
    return scrim.statsJson.find((participant) => {
      return participant.name.includes('GSMC') && Number(participant.team) === 200
    })
  })

  const rsWins = rsGames.filter((filteredScrim) => {
    return filteredScrim.statsJson.find((participant) => {
      return participant.name.includes('GSMC') && participant.win === 'Win'
    })
  })

  const gameStats = {
    blueSideGames: bsGames.length,
    blueSideGamesWon: bsWins.length,
    redSideGames: rsGames.length,
    redSideGamesWon: rsWins.length,
    combinedGames: bsGames.length + rsGames.length,
    combinedWins: bsWins.length + rsWins.length,
  }

  const parsedBSStats = [
    {
      'name': 'Blue side Wins',
      'value': gameStats.blueSideGamesWon
    },
    {
      'name': 'Blue side Defeats',
      'value': gameStats.blueSideGames - gameStats.blueSideGamesWon
    },
  ]

  const parsedRSStats = [
    {
      'name': 'Red side Wins',
      'value': gameStats.redSideGamesWon
    },
    {
      'name': 'Red side Defeats',
      'value': gameStats.redSideGames - gameStats.redSideGamesWon
    },
  ]

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number, name: string, value: number }) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, name, value } = props
    const radius = innerRadius + (outerRadius - innerRadius) * 0.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}% ${name.split(' ')[2]} (${value})`}
      </text>
    );
  };

  return (
    <Container>
      <Paper>
        <Card>
          <Box sx={{ margin: '16px' }}>
            <FormControl sx={{ marginTop: '16px', marginBottom: '16px', minWidth: '160px' }} >
              <InputLabel>Patch</InputLabel>
              <Select
                value={patch}
                label="Patch"
                onChange={handlePatchChange}
                fullWidth
              >
                {patches.map((patch) => {
                  return <MenuItem key={patch} value={patch}>{patch}</MenuItem>
                })}
              </Select>
            </FormControl >
            {!isEmpty(scrimList) &&
              <>
                <Box>
                  <Typography variant="h5" color={"white"}>
                    {scrimList.length} Scrims / {Math.floor((gameStats.combinedWins * 100) / gameStats.combinedGames)}% w/r
                  </Typography>
                </Box>
                <Grid container>
                  <Grid xs={6} sx={{ paddingRight: '16px', backgroundColor: '#202a3e' }}>
                    <ResponsiveContainer width="100%" minHeight="300px">
                      <>
                        <Typography>
                          Blue Side ({gameStats.blueSideGames})
                        </Typography>
                        <PieChart width={250} height={250}>
                          <Pie data={parsedBSStats} labelLine={false} label={renderCustomizedLabel} dataKey="value" cx="50%" cy="50%">
                            {parsedBSStats.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.name.includes('Win') ? '#246840' : '#660A10'} />
                            ))}
                          </Pie>
                        </PieChart>
                      </>
                    </ResponsiveContainer>
                  </Grid>
                  <Grid xs={6} sx={{ paddingLeft: '16px', backgroundColor: '#472a2f' }}>
                    <ResponsiveContainer width="100%" minHeight="300px">
                      <>
                        <Typography>
                          Red Side ({gameStats.redSideGames})
                        </Typography>
                        <PieChart width={250} height={250}>
                          <Pie data={parsedRSStats} labelLine={false} label={renderCustomizedLabel} dataKey="value" cx="50%" cy="50%">
                            {parsedRSStats.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.name.includes('Win') ? '#246840' : '#660A10'} />
                            ))}
                          </Pie>
                        </PieChart>
                      </>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              </>
            }
          </Box>
        </Card>
        {!isEmpty(globalChampStats)
          && globalChampStats
          && blueChampStats
          && redChampStats
          && (
            <CollapsableCard
              defaultExpanded={false}
              keyword="Champion Stats"
              title={'Champion Stats'}
            >
              <ROFLChampionStats
                globalChampStats={globalChampStats}
                blueChampStats={blueChampStats}
                redChampStats={redChampStats}
              />
            </CollapsableCard>
          )}
        {!isEmpty(scrimList) && (
          <CollapsableCard
            defaultExpanded={false}
            keyword="Scrims"
            title={'Scrim List'}
          >
            <>
              {scrimList.map((scrim, i) => {
                const ownTeamId = scrim.statsJson.find((participant) => {
                  return participant.name.includes('GSMC')
                })?.team
                const isWin = scrim.gameWinner.toString() === ownTeamId
                const copyGameId = () => {
                  navigator.clipboard.writeText(`${scrim.fileName.split('.')[0]}`)
                }
                return (
                  <CollapsableCard
                    defaultExpanded={false}
                    keyword="Scrim"
                    title={
                      (
                        <Box onClick={copyGameId}>
                          {`${new Date(scrim.gameDate * 1000).toLocaleDateString()} - ${scrim.gameVersion.slice(0, 5)} - ${scrim.fileName.split('.')[0]}`}
                        </Box>
                      )
                    }
                    key={i}
                    color={isWin ? '#202a3e' : '#472a2f'}
                  >
                    <ROFLMatchDetails match={scrim} teamCode={'GSMC'} />
                  </CollapsableCard>
                );
              })}
            </>
          </CollapsableCard>
        )
        }
      </Paper>
    </Container >
  )
} 