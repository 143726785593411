import patchData from '../assets/data/patchDates.json';

export const getPatchDatesFromVersion = (version: string) => {
  const match = patchData.find((patch) => {
    return patch.RiotPatchName.includes(version) || (patch.CommonPatchName).toString().includes(version)
  })
  if (match) {
    return [match.PatchStartEpoch, match.PatchEndEpoch]
  } else {
    return [1715734861, 1722474061]
  }
}