import { GameNickEntity } from "../types/api/ct/teamDetails"

type Game = 'LOL' | 'VAL' | 'WR'

const gameIds: {
  [x in Game]: string
} = {
  LOL: 'DSqvAwvMpYtH9Ccdj',
  VAL: 'hF3tjoTGcDhtPKpDr',
  WR: 'jxCj5aM8Zx9q69map'
}

export const getNicknameByGame = (gameNicks: GameNickEntity[], game: Game) => {
  return gameNicks.find((gameNick) => {
    return gameNick.id === gameIds[game]
  })?.nick || 'N/A'
}