export const playerMapping = {
  "UNV Gravesen": "MVG PatxiElPirata",
  "Dinojunta Fan": 'MVG ItsCoto',
  "CracklingEnigma": 'MVG Enigma',
  "Tetcho Suehiro": 'MVG Yaavi',
  "Giulia Kitten": 'MVG Kellie',
}

export const replayNameReplacer = (name: string) => {
  return playerMapping[name as keyof typeof playerMapping] || name
}