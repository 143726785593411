import { ReactNode, useContext } from 'react';

import { UserContext } from '../../context/UserContext';
import { isEmpty } from 'lodash';

interface Props {
  teams?: string[];
  games?: string[];
  roles?: string[]
  children: ReactNode;
}

const GenericAuthenticator = ({ teams, games, roles, children }: Props) => {
  const { user, authenticated } = useContext(UserContext);

  const checks = {
    roles: false,
    teams: false,
    games: false
  }

  if (authenticated) {
    if (user.role === 'admin') {
      checks.roles = true
      checks.teams = true
      checks.games = true
    }

    if (!isEmpty(games) && games) {
      games.some((game) => {
        return user.games.some((userGames) => {
          return userGames === game;
        });
      }) && (checks.games = true)
    } else {
      checks.games = true
    }
    if (!isEmpty(teams) && teams) {
      teams.some((team) => {
        return user.teams.some((userTeams) => {
          return userTeams === team;
        });
      }) && (checks.teams = true)
    } else {
      checks.teams = true
    }
    if (!isEmpty(roles) && roles) {
      roles.some((role) => {
        return user.role === role;
      }) && (checks.roles = true)
    } else {
      checks.roles = true
    }
    const OK = Object.values(checks).every((check) => { return check === true })
    return OK ? <>{children}</> : <></>
  }
  return <></>
}

export default GenericAuthenticator;
