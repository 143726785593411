export const WLGRoster = ['Libra', 'Carnage', 'Secrett', "Bibou", 'Bananitoo', 'Nikiyas']

export const WLGAccounts = {
  Libra: [
    {
      "puuid": "SX4INcOaVF51nUytUpeSaqWcCbTJt9vID2fUs6pWjYLKKr3_HKPRNQklQJBFMhV-Ehfoc2NzX5Q37A",
      "gameName": "Librator",
      "tagLine": "pompa"
    },
    {
      "puuid": "fVg_mQOzBTSJcHxyutaxvv9wxvPerIXddqfwkuKGDb2J1X9tbNlPDXVlYqw6rgkv8aa7jRZYRgVCUQ",
      "gameName": "Libra",
      "tagLine": "1v1"
    },
  ]
  ,
  Carnage: [
    {
      "puuid": "Lf1qjXiwdJVwUPVBHaPlE2Foocs-XT2h3_-XPO_vvR28BMv7HkLpc2Xv2gZFddvA6_9pB8t8dlxMWQ",
      "gameName": "Carnagε",
      "tagLine": "EUW"
    },
  ],
  Secrett: [
    {
      "puuid": "5SNmE3CVvRpxZbl-4G6epyldFoznhcPoqOqN2GIuxqEymqMloWaWDzFx6w0l6Dqa1WrAFxsBpCQ3Pg",
      "gameName": "Secret4",
      "tagLine": "EUW"
    },
  ],
  Bibou: [
    { "puuid": "ubNWoDHWCpm8_8YOcHuYXo505_C4p1J3BC_8Xgq6NgQJddPmE3SdYxA29e9TXKS1wpz0eQAgvyl55Q" },
    { "puuid": "bHRnW6GzzMZ01maXxptyB_hTz0vFtGDtxzj1vuBNkjwd3uSezFwbGKMWT8-zDcaBIumHw5lH_B7dyg" },
    { "puuid": "WcCZUKCavsO5jkCs_9DwoGOYZATMGmMv46lxcuy71oPBOVNNacpbzFnlwdvHM4e-1Al2_RPM0Fp_EQ" },
  ],
  Bananitoo: [
    {
      "puuid": "d40QzlWa9q177U3WzPBMpq3fMAdqmjN7gDnIb3i7eQIsMJ_7kB6mCDTCWg-uK1JdpPXybsO0hHshLw",
      "gameName": "banan23",
      "tagLine": "4260"
    },
    { "puuid": "g5AD-59u2POCQptlNgAcZ3m876m5l7u3d7fk3wG8WqdVgG0D43ApNIvzBgoLboY-qrXkGtxBjoJ3yw" }
  ],
  Nikiyas: [
    { "puuid": "CAfYQNl6RWK2WOUWQpuRvWDcFL_n-TfciuNGfeUNRGRrQxI6YOB3Je7uQRhTqu5FGxwqCJ7wpwKLeg" },
    { "puuid": "tPVRrVanNMVvKMb0JvaRhXZlT-7xDtLdN_E87ACgeSP-33QCIkVjTmBKYbOrmCFYEzk0MUD6wGecJg" },
  ],
}

export const ANORoster = ['Papiteero', 'Noodle', 'Pun1sher', "Mishigu", 'iLEVI']

export const ANOAccounts = {
  Papiteero: [
    { "puuid": "D-PsldJfaeycfSPpu5JsO3_F34ccmWuKazPtVbjIAjdBqkHKSaaRaZSjqdVi_gLRlEeZoUF_7HNxJQ", "gameName": "ToothIess", "tagLine": "EUW" }
  ],
  Noodle: [
    { "puuid": "_aynPBfkRh54mjXCzkqwk_2hzAFMNzRSYaKV1WwMWudWJ2f49sQWt3I66RVHqSOckqX7BZ_wqoeNEg", "gameName": "Baker", "tagLine": "SWAG" },
    { "puuid": "8Vj51yEov1y43bJa0RlhEEW1_5DIumPt0-0_hYM4f-oTy7kOPXmCWMycb75M_23z3wOTCje8WCUDCw", "gameName": "Kryptos", "tagLine": "0001" },
  ],
  Pun1sher: [
    { "puuid": "ZvfI8Jyq7nm4CNXhbeqm6HKDE5gWN25Pnj79UmRO6nDAs5F-hgRNefOI11DOpXeMnHcxHRX-z4Q83g", "gameName": "PUWUN1SHER", "tagLine": "UwU" },
    { "puuid": "N8sKjZYi3qTMSt8uXhG9BeJDwISe2CoLsb5pODPpP2tnqwHC45lHhHZHzBFcndE8t7L7XrGVaF-rkA", "gameName": "Pun1sher Reborn", "tagLine": "EUW" },
    { "puuid": "Tl8J0ocKq4pSb4UN8KdY7msajaSMmQRgJMvudZO9KYBWfZh6vUCBNNr2cxpYYK_C5131zD0MHIqwvg", "gameName": "kostaskiller4", "tagLine": "2589" }
  ],
  Mishigu: [
    { "puuid": "6d4VAk817Y-rahSSCRASod1pANj9yyajbwOHAHo2Zlq9-MfhAXLhckGE_b6pCjD51BIpBJS6nio8Dw", "gameName": "Mishigu", "tagLine": "0001" },
    { "puuid": "Km6T1MtK3CBWQBqG2vu_ce0hGkkR4el2MY3r0wkTiUkfSGNUxOMFpvSO5U4eotKP0vaAbUVfES3f7g", "gameName": "Mishigu", "tagLine": "0002" },
    { "puuid": "3lQhlDzLamGPIq8EaVFcnB_a7AB8Xb0MKyjO2FCL7CnUIx8JQIKlBmy3lBMqLniaohL_KFhLStNqwA", "gameName": "Mishigu", "tagLine": "0003" },
  ],
  iLEVI: [
    { "puuid": "85dUbn8Gugi-FWx9kV5APwxP2bDJH14aMtV7SGIkcRjNA-QbhqqcK2J4dbGpNDT8WiEBsruAhqssKQ", "gameName": "Levi", "tagLine": "ALB" },
    { "puuid": "xnsG5jNPqiTjav9lz0Y4bFgAZ6eVwe28QlMNbO3XkoYpgjKCEB5ObFusY70-TTxyN-X-gG4b-nZAAQ", "gameName": "iLevi", "tagLine": "ALB" },
  ]
}

export const TPRoster = ['NuQ', 'Manaty', 'Peppe', "Vaynedeta", 'HungryPanda']

export const TPAccounts = {
  NuQ: [
    { "puuid": "aT0rRqIYMETUUtOLKrF-bftjZ9ZaB281nRPqlBtdYZhQ3BnQYSwJ4sDMvZ9outD8iT39N6OGZwODtg" },
    { "puuid": "KkNNT995jU5zG3dsnnATcpzlgP1GwZEV7VFbn_hS2dYoH-Q0TNtIwAP02kwIFDCh2eZt839f2p5BBA" }
  ],
  Manaty: [
    { "puuid": "sJDzW5p0qinW9YcwqOIRyLPfnLm3RuaJDt_D-OUckAvP-ei1Fg_Ne0boqVKC8lOw42445ThGDD5wHA" },
    { "puuid": "NnUJj0WvLdErMgGyEG9WvdzMMBGfqKVl8wlVNl3MS7rt6kWA63y7mx2gqZ4wu3nVRLn0WOgqQpELiQ" },
    { "puuid": "-ItanAEnrAHLO6CN5Cr1oj3HealUqLmkfptsZC-01_Hdslggtm2A3XcPnlgc9mFiHlt3Xu1sIQSzAw" },
    { "puuid": "HNBT8FnY6sVd3H_9PudkVYF3n4wnUJBM0EgVncLrKoT1zuvVdA4FQz_lLMKcZb5Mc0JhYmFLV2T8Rg" }
  ],
  Peppe: [
    { "puuid": "gl5YMicqPtmrp4lxhp7OWEFZI-cotKvt5gnkICwEDOkcgZQHe9hyqNXYCJir1xwhPYTiYPSGUATEFg" },
    { "puuid": "yLAsCrwIh45sRJYq11TB1lHJ7LUnyeCWd1mEoskXe9Qcz3CYhsKyO-tBOJEbAW9ZtEGNWp2CHMGRoQ" },
  ],
  Vaynedeta: [
    { "puuid": "LhA6fHPYl1JyAjnsz1PrBp6Gltq8i8emfpmpjI020hRakV0bjxQRm818AGKuSaSfQQKutSd6KyxxOg" },
    { "puuid": "QvOru44d5La83qUhsX8uZ4dKfx9_Qf_ms4X7aEnbsMj4yx6Z0us3vJjI6sGd_2gC8K4GSfSzEmGVwQ" },
  ],
  HungryPanda: [
    { "puuid": "qudhjwoPyCIDdCLC8wjmSWLR6UyCsR4BkqwBz5sLyDAR4IdE_FylfiqMl4Glz6yU2GJFGca461hb_A" },
    { "puuid": "-Z_EGsBNtPLG-a3j5RSLZUCUfrDRc8LO92Or89YLpTc9syj2ITwDADAZxFQEHozxiYPEBZRbvAXkUQ" },
  ],
}

export const TPXRoster = ['JohnGiannis', 'Nikolex', 'Jimsnop', "Paparotis", 'Haoswen', 'TheSerius', 'Bloodlust']

export const TPXAccounts = {
  JohnGiannis: [
    { "puuid": "cRPr5xBUDre17sQDkgZ5IG6I9SCwkq6qj7i1haDrhs4UVG4P-IQSj9zKYrjd7z1G8oGPwXg-xPLytA" },
    { "puuid": "8ZhGEqjPDfRsv1tMGVFZhb0XKI632Hdf0fTMlz1LlqfMH5I0xojMXIQ0IYTivcc8hv7NzS00Nu6y5w" },
  ],
  Nikolex: [
    { "puuid": "ncAqQgmFM8BgfpeBI886YNixdJq9OVhcAyISgeRtKdymhPQKkU6Ny7LNbPQoWYukEx0Em2q-0HXtpw" },
    { "puuid": "tW6a0YqptUPw6Xcl4eXaNUFmsTEYpYwkemPSVxvMRl7Sy8P_6pzSxLElF9T9QKnphhxpyl-JteiIHQ" },
  ],
  Jimsnop: [
    { "puuid": "3JLye0w2DzrXbbgvh3ZNiMEgRBusgQXEN5XDWDDNNFlJTLs_OQyq5M9IRgKdNg_s6qk7IPVSoCnXEw" },
  ],
  Paparotis: [
    { "puuid": "L-ZxuC2Jvcl4zLjwPK8UYLKzQ2BH_ev7KxrCQ0D8sRo_RzXGHzCEL5qWaNrrsnxXN6ekBPGRLXFphg", "gameName": "Porta Se Magazi", "tagLine": "EUW" },
  ],
  Haoswen: [
    { "puuid": "9FezDEq085_9_Vsu7RyZReEBJnOcX8RKr-cxAwyfGVqwennaVdufqu0N_aGMJhfDZeLeiu5gAxui5g" },
    { "puuid": "CsnxFHFVXoNor2YelQZwJZq-OD8zibkGPjoRcr1eoXCfbhYfkMpdMmPCNFPzwYchPDtIwIXGdZ43nw" },
  ],
  TheSerius: [
    { "puuid": "J5sJQUs4Hbypr8jAJccloWpI4B7b2XvfKJ1GxqMOLa9E3Z-3RQ4T8dMSMDDJlwE3a7Bp1hznKnmNyw" },
  ],
  Bloodlust: [
    { "puuid": "eOdDiNsaGZ1_hHJfVskOxxg2qkZzwC3iI0Qkle_o-Kp7gC7gBYBOqYMAE9Jlal1qYsTE-M6CbedrJA" },
  ],
}

export const HELLRoster = ['Skye', 'dibu', "Freazy", 'Shakur', 'PsYcraw', 'Dafnis']

export const HELLAccounts = {
  Skye: [
    { "puuid": "ksbxj3dfOEVZ_ikUcwveKeMX1YwA6x6v8l-I02Dq_zAs7C4FVMPVwKXnJ4CNa0_euKm0bdoYykQiHg" },
    { "puuid": "D_WQQSVp6HEVabAqgDb-UDDuRMIBBRMrNHXqXY7-wDQqq5aJotxXs8afGirIMO3WN6WBPhERxg2SdQ" },
    { "puuid": "L_c6zHUxuvtGkIu0E0-JbRDrWNqJ5ON5KCNZCZcqq0RkuYH9NvuOl1euUGKvshkaQgT8KXDHOExSXg" },
  ],
  dibu: [
    { "puuid": "6b2SOUl12o7VLLKd3mRjL7y6_RLnWwCPFgpxPjPJmzaRF3iB6k9XcP51VwJMoB1_TlGm_bcPNK2gWQ" },
    { "puuid": "obgeBFi8auor_JBKqEy-r4kuPFINHAggFDSICM5nrIUBg7iMqBzWrVSlRjgmgdR2IdFGUaCC7eR52A" },
  ],
  Freazy: [
    { "puuid": "cxdKKn8InHm5UHOY1KS6sRy9PafAKuwqfh8Fjas4R7Cjph__bsbezC0bPaVrWIvDhpWsjPRrBkNtEg" },
    { "puuid": "nhk--MPoQGG7WtO5_v9qwFLdwGv-cfXBt_6wX7PvoEfwhmgI8K4H3-aKJBIbMPaOZQP8jd0WSEcySg" },
  ],
  Shakur: [
    { "puuid": "5G3Kg8jVKdwp1yVM7l3AWmKLsJ0MNp-HEvRd79uzAcitKM4fvXkThgTunX7v-kRZs5GXOhPMwCHNQg" },
    { "puuid": "Cn4vRWu_u4x5s9VXtOVdjBG5fcCiuMYZvlDczpVUn2osqy2rCXebzuRgzmjHWdMu6ZigcCSNaDwrbw" },
  ],
  PsYcraw: [
    { "puuid": "5tHC82k0x55io_d_jAUNAav8TLAck0dpWYIHs93zePa5gG-xMPr8DLL1_FFtAsf6-s3P8bLhjR-i-Q" },
    { "puuid": "4ppFnRYT5NaB9rPBGUqbPaiuy-KhQyNQciHEC_glvYHr3Q9N3rTtrv-Js6B_w1LFtTeukJmHxI6VnA" },
  ],
  Dafnis: [
    { "puuid": "jh00jMDvPJtKktgxyz-dx-OgkOGaDRV-LThQ381kX9WifC1xZphHlFOebqrnsmbN3FhNMZ21A2iYVQ" },
  ]
}

export const WPERoster = ['WouLou', 'Psilakhs', "J0J0C", 'Gagai', 'LeoDAras', 'Kebap', 'Rabbit', 'Humble', 'Traffy', 'Mehrio']

export const WPEAccounts = {
  WouLou: [
    { "puuid": "kFN1WHwqQPclO7kYKvrshIR1Qdvjw8Vd4e5qdZxtdjJ9M569ntKJDSvAE7-HFQ5-tMmma2KGkIxRWA" },
    { "puuid": "xfKt2qEIlwGYgoZA_2i3TyUNn7aGIPOsZPe8FJ_YiOEqjZiMhdZM3-4MINw1kEZapFQCgf2Xo7Gw4A" },
  ],
  Psilakhs: [
    { "puuid": "gcXwwk6kGCdJizIB4fd86PdE8WTqVtVtOeJxdlyfWJaFgqK4nn8m7Qv666Th2Q8kRnRKISssb_dMRA" },
    { "puuid": "-i2RrrQny2_ammjqbk6ljdtYeIkJPmdobX3NeYPOPDQaVqt06-9bkU8DILAPGaPnBeOUci_71dDFtw" },
  ],
  Gagai: [
    { "puuid": "0sCLKDPlY-X2avboSLN5C4yGSXbP9k-72nhXLxANMxNImhQOkG_orx5f2zCoA6qrQ3CvRF5nzIYxvQ" },
    { "puuid": "EJKsPfQa3ddcc2QOxDoKUXhCzVfLEWCo7Ut-JccbdwAu8UeH0klTW_j5TFUOOLDEwrXg9lRU9dGNTg" },
  ],
  LeoDAras: [
    { "puuid": "LVFnMMX4jECyN5nv6pn9huZ4bbtoGpwA0NZOEKUVzQYIf986a6cZiZvJGus-KSXPMlt-M0jU4G0wjA" },
    { "puuid": "2ldMYUrgF-3aOqrAVIF9IfAv2CdaLXJeQopujVuO_k8jV57K8Ocbyy-xeSS_DVUABkr5qJjmSv1c4w" },
    { "puuid": "mHma0sjg2hTCLkuG1rYxA3P8UNAc5fHmU3usElf3FJ3Ssw3VUN6z-TZVLiHubK5EGoZpoKKvEXxz-A" },
    { "puuid": "fYI3-SoLCeAT1wf1voSMxjpchmSDoSihgM4ecAv0_mwW515cspHAHWr5fOL1bHyB1ACEGA3Wgt_wdA" },
    { "puuid": "SoJ0DVX-6gmbupRIo0Nm0DdR_Wjox1bBfU4eQ06AUHZgMgtXbVtXqvgxtoVtr0Pq2Y4Gw7IN7mSn6Q" },
    { "puuid": "PtcWeGrC8NhS3-Y0MBiztbLyZIrvg16SqVgMGeYm_IjQolc_NDlnmCNdG6N2mSCqT81s_UqK7jG2BQ" },
    { "puuid": "ZYHXR1SkVxJ6ciHEVoLn589hGzZN63Ij_gowLYqvyvMBOBZbfrFWk67zAr72YuXN6vvfeB25ojfb5g" },
    { "puuid": "o4xIkcR-EAavlB8BfZrEdLtzA6JE5VTfNqiEo8-Pjk4gf3R9T-gck1wPYKmajtpETVvDrsJt4nIf6Q" },
    { "puuid": "XDZnA05ktpXaMV4d9oHrF8KffWpGHQsyU897CNqzJNVL6V6BLRjK5tZSHESGCsJxBEJ6j2HTUNUjzA" },
  ],
  Kebap: [
    { "puuid": "7Sa0F3NhT266zN98o9u5Ab5_SVY4C4642gebngwJ_OyzHRQR7uE4rnzA5BxDDx8PTQSUSWYsTfWlvA" },
    { "puuid": "ElOyJ9YsQaHDfFDxpRqfHs8sts8q-13y5PqkpvYaQd7ApkkHWPaskEVzKf3xdyFfgaGqRZ_E7AXHNQ" },
  ],
  Rabbit: [
    { "puuid": "sT9LGUwvu_3FzPZRgngjWiYMM6K5WhYaZgWNdct2pQdCeoFsw1iSLWILyGA86-QBkTTnyVWdyS0CPQ" },
  ],
  J0J0C: [
    { "puuid": "bzcNIQah5665a2KQ8HdWt-lJi98YAPEh2gyl1YOgRhLAiQb58gZjhQK5UrJzMkOAYZlVAVpM8sF1Zw" },
    { "puuid": "hhijC2Exc63EgNVkEZTZ-pkdKBIQEBvjWdwo-ElhDg7CC1X4Wr_ty8X72nzB3QZ44z2KIAin200xbg" },
    { "puuid": "OQqWk-pbWkLy5hQ5QS_YD884vtCedPaDW3_QP0wl3pPIQiBdJOwXB6A5xpKcqnojb7LEvc9vTxitiw" },
  ],
  Humble: [
    { "puuid": "sE0qFWXDAmecwMSgBWLwkZKiOLF9RKcORehOppwAseATOSwAb7YxMZtr2Ymx1V6oC4m7UF0tIdT-yQ" },
  ],
  Traffy: [
    { "puuid": "x_koME6fG6GzbVQU1_RaWqpWnRneKDDRaS4pOR5S7mUbtLbOHwfLKBeVHJp-eq5A06fdGM4WjgyRbQ" },
    { "puuid": "0OE2qldn9S7n03AaMapm3ZArWGHoUH5nnH74bQlvO7g-0xLFysSbnCm2VNfu41AJDfHPclbiNCVetg" },
  ],
  Mehrio: [
    { "puuid": "xAQvAqj6Eywb9xkVrhItDQO0xUypYZ2aR6NoilMydG9cB8FjqtJNIygJ5-SbzG29oQCtVhzXY4Gz4g" },
  ]
}

export const RFSRoster = ['bobista', 'Pallet', "Mentos", 'raining', 'Gordinho', 'DANKI', 'Eyen', 'Gosberg']

export const RFSAccounts = {
  bobista: [
    { "puuid": "oHWUiDAsItlznOiRQX2ZzBWizbxoxYQUBZdB_NQZ1p1KElirj1soKB_2pGX6lyXNgn00u9oMMDSnHQ" },
    { "puuid": "M98QSxhE-mN0dcC3O3O-t8a_Bkw2SwgQRONtsjL1mSIGWXHTVl8qFM2VZErX4051YdVPTdytpEKu0w" },
  ],
  Pallet: [
    { "puuid": "st1za-aBG_Rw8O5lLEdv69-4fPa1gEkHev92sYIeigUpTnPEKUz5KeRVnil8R9bAbnBQGTby-pJ6xQ" },
    { "puuid": "VDVl8ZYl5PBm1EosAOFK-N6ioldW29kB-zuAp_M6h_y2HE-ggX0RBsWt8XQ8mF7caMmfkV2r6eUWAg" },
    { "puuid": "7eQx-aLqlpkIB059XQMAZpAlad6O1Y-U6JxRd5SenqN6-GwBRgHaziVoMSLEA4Eo4qZrnYLgcb1SvQ" },
  ],
  Mentos: [
    { "puuid": "vgfCLQI8vByG-EamTVWzcohLHNOkZ3b_9tCUKR1TXEjY0jrXNosoMdtyuTAIUVRngHk69HYSuACYEw" },
    { "puuid": "Nqy2fTbfbUgzdQ5skjKByM6_2L0HV4XmnnnDKTru2pDIv5etpPuZmFNic-rs23UBu6IVxTowbRNKOQ" },
    { "puuid": "nhrsPIm-htNX9wG5X3-gX_JJcTaDGKoQdmjrCcWtmjlOb-LtoSkjuZoLacNGq_vwJJDuhnZfTcP6eA" },
  ],
  raining: [
    { "puuid": "f-Z6BqxNo2WCWSnZFFeJLNl5F0iL6IXW9xbU25oT8GPKLUFB1Cnp3IVBK2zfbhiZV8hoXn-zElkAhw" },
    { "puuid": "xEGDs36ZMzuzy1585OSmjEIK67IO5J2PF9G61dTitSIHZGnYRSGGmmpRWX8fkHjXPBNlU27osZuLuQ" },
  ],
  Gordinho: [
    { "puuid": "dgU5izfIAkfofXv1682pZdx_ytWHgv8TYJi2nV_DYNTqekDpB65-APJM8OVYZw9MWxH0YqTgq1TSFg" },
    { "puuid": "R82WQckIDoPZRqmL0AdXFtRgCSa-ytJL7L78mVxIwdYt5BVgKD1kEAvk4grxmvGkIJFys-j81tZQhQ" },
    { "puuid": "7rU1AMI8uMinTUxp8STuSXOcE2sRWtRnuvMhMGVd0eqbePb5siwo_0SjB8GcCdZT3UVU5APYzdRR7g" },
  ],
  DANKI: [
    { "puuid": "M1O8n1VPw789YZUZFR6VNnLi8noQh1Lr6aepji_26zbKnzkT6enF2_X7uq51ZIB_DjfbeRrv32etJA" },
    { "puuid": "rUxGxjS2tcJIMN1Z7BEUREw9vZCNk9fOr45TQgSHVXWicpCKj97jCfDzLL0rmHpYksXWJsjaSXUZpg" },
  ],
  Eyen: [
    { "puuid": "Omxk9y5csEGyoLn6L3zU-DIpj6-vGthjKo391jiYl3UDa1ljbtyZbzbCVg6ohXv0eP5zTQlwzEIgaw" },
    { "puuid": "GuKkHV3RMYuUg7xRH31O-wcKLiY_04ChTRaQAFwEZwvTtGJC8c5WbLgF2EhIiDhsplFC1RpIJ1G3bw" },
  ],
  Gosberg: [
    { "puuid": "0bG7gisfJbSC_2E0jByRIj7F7NBHlrJwlItoyUzqrtgALzXZgm3CX2nZw6HWlPEHi8gMdYYGWCIdvQ" },
    { "puuid": "9niKvL4N8GjPtntEQzqBpBHXYIH_jGzzUzph4JeMF7eru87sQU-RkALGz-bcplGnBqaxQOTdWOMBTw" },
  ]
}

export const GSMCRoster = ['IceBreaker', 'Savero', "Kanin", 'GOLDENTO4ST', 'Kasing']

export const GSMCAccounts = {
  IceBreaker: [
    { "puuid": "VNy4UE9wwKlSw8JlhXzQIZ2ZAXfUTBvbWqiXbUGXWZWaH_UJyHBFsrB7lAAmd_qNsZxYANfSK6Y8XA" },
    { "puuid": "7_9OLo4whcdhSIL7W6S-8xrGX4L03iqHzqUi6VZLwvnbkkoujr7eMY05qHMHUFxCOrlvOH71xMn7wg" },
    { "puuid": "GtELpQbGq4LoxkEb_5fk4ARQDl_caBEJujBnyqJVWjhb17WF_lbk7XaVX0HYsnMxuE6QOXvH0nNuEg" },
    { "puuid": "l6jmY1i-ur-qTyvUDuTPmwrsFA5mCfIL09yR7v8PMFW1vKakR2QBJKK0N4WtSYjtJxwAVX-yOlYqlg" },
  ],
  Savero: [
    { "puuid": "woHG5m6wDmJdCsiZHDWRz5XfndzgqZ7xUeFOrRq67Q5Mz28qkM0FI78YrJ_F0P8ViwhQmNgHWvcdwg" },
    { "puuid": "hj6Ii5vNkuKQz9-sFyzbFshcf0EuREdkJ0CZkoXg1CB6MCR0PzIr8yOz_oKlopSkwIL4I2yyj9lrMg" },
    { "puuid": "tYXFpTf8nKnPooxUUtRl3qtrusuRCavyO-6SbiLgDUd_x63jMqTeKyqhtp-O4e2wALXs3KL5etUAtA" },
  ],
  Kanin: [
    { "puuid": "koQpBEM80lWF6rETXz5C8UP32952NGCSJO85CiIQXfxg21mpBqiYkKEwX7Fl3q5sC1J21MgFDTv3gQ" },
    { "puuid": "iA13RqknQ6wMYavmaWXpBD2eYnpCm6f_jyIRFnb_q4-c7dHz1exvMVcjw2n7egx5Tq1sNFBfqdYXfA" },
  ],
  GOLDENTO4ST: [
    { "puuid": "xJCBiV2w0eWNZ__OMewcmDqvTL0vUHhjhr40b9bP1PVwkqi7izmHFTlo-1-pc6Q72lVg3kePFAF61A" },
    { "puuid": "hCtX2rs3ID71wjR_gniZWjbKrCeqJNyNoHgqNJt16uFtLZipm1U0MfCkOZFAXxHvj_3_9Ol7lk5rvA" },
    { "puuid": "bgOSCc3OCCkcejWd3KXrPUuJyTrnvHXUBf9p5Q8yuGUr2XJCZM7SzJ-aoD9IrjBKbPabPHLhb6KVfw" },
    { "puuid": "9TjbnvV6sTSpcucVnf8lSZNQKjamoPz1bpX8Oj2lwNcQ9cBrDTYuPSRFI38OJ_0ZaA_BwD52IjEJTA" },
    { "puuid": "oDU8a49By4gzITjaH1YXuQKVK9jcEJ2aCtE5rlaV5AHQTpKCbv5N5sKg2_mZH9Kz0sJArgsswlJeNA" },
  ],
  Kasing: [
    { "puuid": "INV6jzndGTedN5ceTv56nceeIEavjG9xAhXRQOoIW4N4pe0yyf333L8GWsKFxDkf5iR3Kwqq1Fffpw" },
    { "puuid": "vPXxKgqjExAY84eaWtuM9D0546UebL7tzpDK80dP0JQN8bLmx9zLnraqc5fYgmrYivepfPzlUncmNw" },
    { "puuid": "fhefvwPJ1QJwPdK9Xmk7FrDuqNyRgmgAHhdMGKxGIlJ15poWD7HL0K9YTADYLU5w2aIpB2RAQsiPRg" },
    { "puuid": "2QR2P1l-EKytbB94uws1d5-LwlEe_yQS1ydJ_v4bZdco6UOJlx94xHg6fMF9dYpauxUhRbxrsinH2g" },
    { "puuid": "A1BhnfmXO2mV6Ur_dSzAfAYYzd-cew7amDAtZzRl-_Y2fir5WbtVtth9abR0EjgRyxRBn8i_wjqJaA" },
    { "puuid": "RXllXXcif4iQl6qC-JHTIRLvxoG4rXYqf5BYUJJHTUh5oe6e0f6XHjpFBmnxb8UOqyCvuNxlIh4V5A" },
  ]
}