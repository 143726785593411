import { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import {
  Container,
  Paper,
  Button,
  Card,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import {
  ParsedScrimElement,
  ParsedScrimList,
} from '../../../types/api/bayes/scrimList';
import GenericAuthenticator from '../../../components/common/GenericAuthenticator';
import { getDownload, getScrims, ingestScrimSummary, postDownload } from '../../../services/api/grid';
import { SeriesFile, SeriesNode } from '../../../types/api/grid/grid';
import { LoadingContext } from '../../../context/LoadingContext';

export const Scrims = () => {
  const [downloadModalVisible, setDownloadModalVisible] = useState(false)
  const [downloadList, setDownloadList] = useState<SeriesFile[]>()
  const [scrimList, setScrimList] = useState<ParsedScrimList>();
  const [previousCursors, setPreviousCursors] = useState({
    endCursor: '',
    startCursor: ''
  })
  const [cursors, setCursors] = useState({
    endCursor: '',
    startCursor: ''
  })

  const { isLoading, setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true)
    getScrims(cursors).then((data) => {
      const endCursor = data.pageInfo.hasNextPage ? data.pageInfo.endCursor : '';
      const startCursor = data.pageInfo.hasPreviousPage ? data.pageInfo.startCursor : '';
      setPreviousCursors({ endCursor, startCursor })
      setScrimList(parseScrimList(data.edges));
      setIsLoading(false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursors]);

  const parseScrimList = (nodes: SeriesNode[]): ParsedScrimList => {
    const parsedGames = nodes.map((node, i) => {
      const game = node.node
      return {
        id: i + 1,
        date: new Date(game.startTimeScheduled).getTime(),
        team1: !isEmpty(game.teams[0])
          ? `${game.teams[0].baseInfo.name}`
          : 'Team 1',
        team2: !isEmpty(game.teams[1])
          ? `${game.teams[1].baseInfo.name}`
          : 'Team 2',
        gameId: game.id,
        ingestGameId: game.id,
      };
    });

    if (!isEmpty(nodes)) {
      return parsedGames;
    } else {
      return [{}] as unknown as ParsedScrimElement[];
    }
  };

  const handleDownloadModal = (gameId: string) => {
    getDownload(gameId).then((data) => {
      setDownloadList(data.files)
      setDownloadModalVisible(true)
    })
  }

  const getDataRows: GridRowsProp = (scrimList as GridRowsProp) || [];

  const downloadButton = (gameId: string) => {
    return (
      <Button variant={'outlined'} onClick={() => handleDownloadModal(gameId)}>
        Download
      </Button>
    );
  };

  const ingestButton = (gameId: string) => {
    return (
      <GenericAuthenticator roles={['admin']}>
        <Button variant={'outlined'} onClick={() => ingestScrimSummary(gameId)}>
          Ingest
        </Button>
      </GenericAuthenticator>
    );
  };

  const handleDownloadButton = (url: string) => {
    setIsLoading(true)
    postDownload(url)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      description: '',
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return format(new Date(params.value), 'dd MMM, H:mm');
      },
    },
    {
      field: 'team1',
      headerName: 'Team 1',
      description: '',
      sortable: true,
      width: 200,
    },
    {
      field: 'team2',
      headerName: 'Team 2',
      description: '',
      sortable: true,
      width: 200,
    },
    {
      field: 'gameId',
      headerName: 'Download',
      description: '',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return downloadButton(params.value);
      },
    },
    {
      field: 'ingestGameId',
      headerName: 'Ingest',
      description: '',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return ingestButton(params.value);
      },
    },
  ];

  const handlePages = (direction: string) => {
    direction === 'prev' && (
      setCursors({ startCursor: '', endCursor: previousCursors.startCursor })
    )
    direction === 'next' && (
      setCursors({ startCursor: previousCursors.endCursor, endCursor: '' })
    )
  }

  return (
    <Container>
      <Paper>
        <Card className={'cardSpacing'}>
          {scrimList && (
            <>
              <DataGrid
                rows={getDataRows}
                columns={columns}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10]}
                autoHeight
              />
              <Button variant='outlined' disabled={previousCursors.startCursor === ''} onClick={() => handlePages('prev')}>Prev 50</Button>
              <Button variant='outlined' disabled={previousCursors.endCursor === ''} onClick={() => handlePages('next')}>Next 50</Button>
            </>
          )}
        </Card>
        <Dialog
          onClose={() => setDownloadModalVisible(false)}
          open={downloadModalVisible}
        >
          <DialogTitle>
            {(downloadList && !isEmpty(downloadList.filter((file) => {
              return file.status === 'ready'
            }))
              ? "Downloads"
              : "No downloads available"
            )}
          </DialogTitle>
          <List sx={{ pt: 0 }}>
            {downloadList && downloadList.map((file) => {
              if (file.status === 'ready') {
                return (
                  <GenericAuthenticator roles={file.description.includes("Replay") ? ['player', 'staff'] : ['']}>
                    <ListItem key={file.id}>
                      <ListItemButton onClick={() => handleDownloadButton(file.fullURL)} disabled={isLoading}>
                        <ListItemText primary={file.description} />
                      </ListItemButton>
                    </ListItem>
                  </GenericAuthenticator>
                )
              }
              return <></>
            })}
          </List>
        </Dialog >
      </Paper>
    </Container>
  );
};
