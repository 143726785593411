import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { UserDetails } from '../types/api/auth/login';

interface UserProviderProps {
  children: ReactNode;
}

interface UserContextType {
  user: UserDetails;
  authenticated: boolean;
  setUser: Dispatch<SetStateAction<UserDetails>>;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
  handleLogout: () => void;
}

export const UserContext = createContext<UserContextType>({
  user: {} as UserDetails,
  authenticated: false,
  setUser: () => { },
  setAuthenticated: () => { },
  handleLogout: () => { },
});

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserDetails>({} as UserDetails);
  const [authenticated, setAuthenticated] = useState<boolean>(false as boolean);

  const handleLogout = () => {
    setUser({} as UserDetails);
    setAuthenticated(false);
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, authenticated, setAuthenticated, handleLogout }}
    >
      {children}
    </UserContext.Provider>
  );
};
