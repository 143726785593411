import {LoginForm, UserDetails} from '../../../types/api/auth/login';
import {baseURL, baseHeaders} from '../../../utils/fetch';

import { customFetch } from '../api';

const authUrl = `${baseURL}/auth`;

export const postLogin = async (data: LoginForm) => {
  return await customFetch(`${authUrl}/login`, {
    method: 'POST',
    headers: baseHeaders,
    body: JSON.stringify(data, null, '\t'),
  }).then(async (data) => {
    const response = await data.json();
    return response as UserDetails;
  });
};
