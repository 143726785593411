import { flatten, groupBy, isEmpty } from "lodash"
import { useContext, useEffect, useState } from "react"
import { Box, Container, Paper } from "@mui/material"

import { getBGZTryouts } from "../../../services/api/atlas"
import CollapsableCard from "../../../components/common/CollapsableCard"
import { ROFL2SummaryList } from "../../../types/api/atlas/ROFL2 Summary"
import { ROFLMatchDetails } from "../../../components/ROFLMatchDetails"
import { ROFLChampionStats } from "../../../components/ROFLChampionStats"
import { GenericChampionStats } from "../../../types/processing"
import { LoadingContext } from "../../../context/LoadingContext"

export const BGZTryouts = () => {
  const [scrimList, setScrimList] = useState<ROFL2SummaryList>([])
  const [globalChampStats, setGlobalChampStats] = useState<GenericChampionStats>()

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true)
    getBGZTryouts().then((data) => {
      setIsLoading(false)
      setScrimList(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setGlobalChampStats(procChampStats())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrimList])


  const procChampStats = () => {
    const mergedPositions = groupBy(
      flatten(
        scrimList.map((scrim) => {
          return scrim.statsJson
        })
      ), "name"
    )
    const championsByPosition = Object.entries(mergedPositions).map(([playerName, playerPicks]) => {
      const proc = groupBy(playerPicks, "skin")
      return { [playerName]: proc }
    })

    const globalChampionStatsByP = flatten(
      championsByPosition.map((player) => {
        return Object.entries(player).map(([playerName, playerPicks]) => {
          const ret = Object.entries(playerPicks).map(([championName, championGames]) => {
            const championStats = {
              [championName]: {
                kills: 0,
                deaths: 0,
                assists: 0,
                games: 0,
                wins: 0
              }
            }
            // eslint-disable-next-line array-callback-return
            championGames.map((match) => {
              championStats[championName].kills += Number(match.championsKilled)
              championStats[championName].deaths += Number(match.numDeaths)
              championStats[championName].assists += Number(match.assists)
              championStats[championName].games += 1
              if (match.win === 'Win') {
                championStats[championName].wins += 1
              }
            })
            return championStats
          }
          )
          return { [playerName]: Object.assign({}, ...ret) }
        })
      })
    )
    return Object.assign({}, ...globalChampionStatsByP)
  }

  return (
    <Container>
      <Paper>
        {!isEmpty(globalChampStats)
          && globalChampStats
          && (
            <CollapsableCard
              defaultExpanded={false}
              keyword="Champion Stats"
              title={'Champion Stats'}
            >
              <ROFLChampionStats
                globalChampStats={globalChampStats}
              />
            </CollapsableCard>
          )}
        {!isEmpty(scrimList) && (
          <CollapsableCard
            defaultExpanded={false}
            keyword="Tryouts"
            title={'Tryouts List'}
          >
            <>
              {scrimList.map((scrim, i) => {
                const copyGameId = () => {
                  navigator.clipboard.writeText(`${scrim.fileName.split('.')[0]}`)
                }
                return (
                  <CollapsableCard
                    defaultExpanded={false}
                    keyword="Tryouts"
                    title={
                      (
                        <Box onClick={copyGameId}>
                          {`${new Date(scrim.gameDate * 1000).toLocaleDateString()} - ${scrim.gameVersion.slice(0, 5)} - ${scrim.fileName.split('.')[0]}`}
                        </Box>
                      )
                    }
                    key={i}
                    color={'#202a3e'}
                  >
                    <ROFLMatchDetails match={scrim} teamCode={''} />
                  </CollapsableCard>
                );
              })}
            </>
          </CollapsableCard>
        )
        }
      </Paper>
    </Container >
  )
} 