interface Props {
  avatarUrl: string;
}

const defaultCTAvatar =
  'https://cdn-ggtech.s3.eu-west-3.amazonaws.com/uploads/userAvatars/default-user.svg';

const CTProfileImage = ({avatarUrl}: Props) => {
  const imgUrl = `${encodeURI(avatarUrl)}`;
  return imgUrl ? (
    <img
      height='50'
      width='50'
      src={`${encodeURI(imgUrl)}`}
      alt=''
      onError={({currentTarget}) => {
        currentTarget.onerror = null;
        currentTarget.src = `${defaultCTAvatar}`;
      }}
    />
  ) : (
    <></>
  );
};

export default CTProfileImage;
