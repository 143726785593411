import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Container,
  Paper,
  Autocomplete,
  TextField,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  Unstable_Grid2 as Grid,
  Box,
} from '@mui/material';
import ReactCountryFlag from 'react-country-flag';

import {
  Tournaments,
  LandingTournaments,
  ParsedTournaments,
  ParsedTournament,
} from '../../../types/api/ct/tournament';
import {
  Equipo,
  TournamentDetails,
} from '../../../types/api/ct/tournamentDetails';
import { TeamDetails } from '../../../types/api/ct/teamDetails';

import {
  getLandingTournaments,
  getTeamDetails,
  getTournamentDetails,
  getTournaments,
} from '../../../services/api/ct';

import CTProfileImage from '../../../components/Images/CTProfileImage';
import { ValorantTrackerImageLink } from '../../../components/Images/ValorantTrackerImage';
import { getNicknameByGame } from '../../../utils/ct';

export const ValCT = () => {
  const [tournaments, setTournaments] = useState<ParsedTournaments>([]);
  const [landingTournaments, setLandingTournaments] =
    useState<ParsedTournaments>([]);
  const [tournamentOptions, setTournamentOptions] = useState<ParsedTournaments>(
    []
  );
  const [selectedTournament, setSelectedTournament] =
    useState<ParsedTournament>();
  const [selectedTournamentDetails, setSelectedTournamentDetails] =
    useState<TournamentDetails>();
  const [selectedTeam, setSelectedTeam] = useState<Equipo>();
  const [selectedTeamDetails, setSelectedTeamDetails] = useState<TeamDetails>();

  const parseTournaments = (tournaments: Tournaments | LandingTournaments) => {
    const parsedTournaments = tournaments.map((tournament) => {
      return { name: tournament.name, slug: tournament.slug };
    });
    return parsedTournaments as ParsedTournaments;
  };

  useEffect(() => {
    getTournaments('val').then((data) => {
      setTournaments(parseTournaments(data));
    });
    getLandingTournaments('val').then((data) => {
      setLandingTournaments(parseTournaments(data));
    });
  }, []);

  useEffect(() => {
    if (
      !isEmpty(tournaments) &&
      !isEmpty(landingTournaments) &&
      isEmpty(tournamentOptions)
    ) {
      const parsedTournaments = [...tournaments, ...landingTournaments];
      return setTournamentOptions(parsedTournaments);
    }
  }, [tournaments, landingTournaments, tournamentOptions]);

  useEffect(() => {
    selectedTournament
      ? getTournamentDetails(selectedTournament.slug).then((data) => {
        setSelectedTournamentDetails(data);
      })
      : setSelectedTournamentDetails(undefined);
  }, [selectedTournament]);

  const handleTournamentSelection = (
    event: any,
    newValue: ParsedTournament | null
  ) => {
    setSelectedTeam(undefined);
    setSelectedTeamDetails(undefined);
    setSelectedTournamentDetails(undefined);
    newValue
      ? setSelectedTournament(newValue)
      : setSelectedTournament(undefined);
  };

  const handleTeamSelection = (event: any, team: Equipo | null) => {
    if (team) {
      setSelectedTeam(team);
      getTeamDetails(team.slug).then((data) => {
        setSelectedTeamDetails(data);
      });
    } else {
      setSelectedTeam(undefined);
      setSelectedTeamDetails(undefined);
    }
  };

  return (
    <Container>
      <Paper>
        <Card className={'cardSpacing'}>
          <Autocomplete
            disablePortal
            id='tagAutocomplete'
            options={tournamentOptions}
            getOptionLabel={(tournament) => tournament.name}
            value={selectedTournament}
            onChange={handleTournamentSelection}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label='Tournament' />
            )}
          />
          {selectedTournamentDetails &&
            selectedTournamentDetails.equipos &&
            !isEmpty(selectedTournamentDetails?.equipos) && (
              <Autocomplete
                disablePortal
                id='tagAutocomplete'
                options={[...selectedTournamentDetails.equipos]}
                getOptionLabel={(equipo) => equipo.name}
                value={selectedTeam}
                onChange={handleTeamSelection}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label='Team' />}
              />
            )}
          {selectedTeamDetails && (
            <CardContent>
              <Box style={{ textAlign: 'center' }}>
                <CardMedia
                  component='img'
                  height='150'
                  width='150'
                  image={`${selectedTeamDetails.equipo.logo}`}
                />
                <Typography variant='h2'>
                  {selectedTeamDetails.equipo.name}
                </Typography>
              </Box>
              <Grid container>
                {selectedTeamDetails.miembros.map((member, i) => {
                  return (
                    <Grid xs={4} key={i}>
                      <CardHeader
                        avatar={
                          <CTProfileImage avatarUrl={member.profile.avatar} />
                        }
                        title={
                          <Box>
                            <Typography>
                              {member.username}
                              {` `}
                              <ReactCountryFlag
                                svg
                                countryCode={member.country}
                                title={member.country}
                              />
                            </Typography>
                          </Box>
                        }
                        subheader={(
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <ValorantTrackerImageLink
                              riotId={getNicknameByGame(member.profile.gameNicks, 'VAL')}
                              size={25}
                            />
                            <Typography
                              sx={{ marginLeft: '8px' }}
                            >
                              {getNicknameByGame(member.profile.gameNicks, 'VAL')}
                            </Typography>
                          </Box>
                        )
                        }
                      >
                      </CardHeader>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          )}
        </Card>
      </Paper>
    </Container>
  );
};
