const nodeEnv = process.env.NODE_ENV;

export const baseHeaders = {
  accept: '*/*',
  'accept-language': 'en-US,en;q=0.9,es;q=0.8,es-419;q=0.7,es-ES;q=0.6',
  'content-type': 'application/json;charset=UTF-8',
  'sec-ch-ua': '"Opera";v="95", "Chromium";v="109", "Not;A=Brand";v="24"',
  'sec-ch-ua-mobile': '?0',
  'sec-ch-ua-platform': '"Windows"',
  'sec-fetch-dest': 'empty',
  'sec-fetch-mode': 'cors',
  'sec-fetch-site': 'same-site',
  'Accept-Encoding': 'gzip, deflate, br',
};

export const baseURL = nodeEnv === 'development' ? '' : '/api';
