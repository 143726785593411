import { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Unstable_Grid2 as Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import { Match, Participant, Team } from '../types/api/riot/match';
import { getChampionNameFromId, getSummonerNameFromId } from '../utils/riot';
import { getMatch } from '../services/api/riot';
import ChampionImage from './Images/ChampionImage';
import ItemImage from './Images/ItemImage';
import SpellImage from './Images/SpellImage';

interface Props {
  match: string;
}

export const MatchDetails = ({ match }: Props) => {
  const [matchDetails, setMatchDetails] = useState<Match>();

  useEffect(() => {
    getMatch(match).then((data) => {
      setMatchDetails(data);
    });
  }, [match]);

  const getTeamTitle = (team: Team) => {
    const parsedTitle = `${team.teamId === 100 ? 'Blue Side' : 'Red Side'} ${team.win ? '(Victory)' : '(Defeat)'
      }`;
    return parsedTitle;
  };

  const getTeamPlayerData = (participants: Participant[], teamId: number) => {
    const filteredParticipants = filter(
      participants,
      (participant: Participant) => {
        return participant.teamId === teamId;
      }
    );
    return filteredParticipants;
  };

  return matchDetails ? (
    <Card>
      <CardHeader
        title={new Date(
          matchDetails.info.gameStartTimestamp
        ).toLocaleDateString()}
      ></CardHeader>
      <CardContent>
        <Grid container>
          {matchDetails.info.teams?.map((team) => {
            return (
              <Grid xs={6} key={team.teamId}>
                <Typography variant='h4'>{getTeamTitle(team)}</Typography>
                <Typography variant='h5'>Bans</Typography>
                <Grid container columns={10}>
                  {team.bans.map((ban) => {
                    return (
                      <Tooltip key={ban.championId} title={`${getChampionNameFromId(String(ban.championId))}`} arrow placement="top">
                        <Grid xs={2} key={ban.championId}>
                          <ChampionImage championId={ban.championId} />
                        </Grid>
                      </Tooltip>
                    );
                  })}
                </Grid>
                <Typography variant='h5'>Picks</Typography>
                {getTeamPlayerData(
                  matchDetails.info.participants,
                  team.teamId
                ).map((participant) => {
                  return (
                    <Grid xs={12} key={participant.participantId} sx={{display: 'flex', alignItems: 'center'}}>
                      <Box sx={{display: 'flex'}}>
                        <Tooltip title={`${getChampionNameFromId(String(participant.championId))}`} arrow placement="top">
                          <>
                            <ChampionImage
                              championId={participant.championId}
                            />
                          </>
                        </Tooltip>
                        <Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <SpellImage
                              spellId={getSummonerNameFromId(
                                participant.summoner1Id
                              )}
                            />
                            <SpellImage
                              spellId={getSummonerNameFromId(
                                participant.summoner2Id
                              )}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{flex:"auto"}}>
                        <Tooltip title={participant.summonerName} arrow placement="top">
                          <Typography>
                            {`${participant.riotIdGameName}#${participant.riotIdTagline}`}
                            {' '}
                            {`${participant.kills}/${participant.deaths}/${participant.assists}`}
                          </Typography>
                        </Tooltip>
                        <Grid container columns={16}>
                          <Grid xs={2}>
                            <ItemImage
                              itemId={participant.item0}
                              matchDate={matchDetails.info.gameCreation}
                              size={30}
                            />
                          </Grid>
                          <Grid xs={2}>
                            <ItemImage
                              itemId={participant.item1}
                              matchDate={matchDetails.info.gameCreation}
                              size={30}
                            />
                          </Grid>
                          <Grid xs={2}>
                            <ItemImage
                              itemId={participant.item2}
                              matchDate={matchDetails.info.gameCreation}
                              size={30}
                            />
                          </Grid>
                          <Grid xs={2}>
                            <ItemImage
                              itemId={participant.item3}
                              matchDate={matchDetails.info.gameCreation}
                              size={30}
                            />
                          </Grid>
                          <Grid xs={2}>
                            <ItemImage
                              itemId={participant.item4}
                              matchDate={matchDetails.info.gameCreation}
                              size={30}
                            />
                          </Grid>
                          <Grid xs={2}>
                            <ItemImage
                              itemId={participant.item5}
                              matchDate={matchDetails.info.gameCreation}
                              size={30}
                            />
                          </Grid>
                          <Grid xs={2}>
                            <ItemImage
                              itemId={participant.item6}
                              matchDate={matchDetails.info.gameCreation}
                              size={30}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
      <CardContent>
        <Button disabled={true}>Show Stats</Button>
      </CardContent>
    </Card>
  ) : (
    <></>
  );
};

