export const B2TGRoster = ["Kozi", "ShazQ", "Syzyf", "Lothen", "Acorderr", "Salami"]

export const B2TGAccounts = {
  Kozi: [
    { "puuid": "KyMDOAs-RtAF2IeEYLWS7nnsuM4ZmX6CiHVetl_RC90bF1D3-JaluPDvzS0Sclk5z1nPDYR-MC2B1A" },
  ],
  ShazQ: [
    { "puuid": "YzA4Ub5A0EdcARYBNx5x5mLtiDo_hK5ozRxtn4acwhmxzWhWw8QUSuaBmUF4mIo1u4Imv7oRbGAznw" },
  ],
  Syzyf: [
    { "puuid": "w4nXOvX5hTMNoflUszYqqn-PLQn4gFNo_J0jgVV2nAS9W9QogFLA8W4YsyeryzN7KqSd7WpvlcuUog" },
    { "puuid": "7LsgdgGGSUjndFrrAa7dhzXLx-_7CmEqJP1wDputvMr811V1jQcZ2Y7y8km2ZKG-yLQiUsWWHPJvEA" },
  ],
  Lothen: [
    { "puuid": "M3aJQ-TEZRvHYE20OrSY1V081hIbz0XUGRiHahQickjhs7iT-e-A8dutIl3aF-EdKJz2qyyZUmdhAQ" },
    { "puuid": "eQhEJkYXvJ-Fifvmw32jyylHOkw9Z5D2MPKlmqiUsB3rQMquwvBWhaSaTzez-VsOLlZj5ZagBqaK3Q" },
  ],
  Acorderr: [
    { "puuid": "NwUZ4ougVAgtOg9EP4H9_gL77cocLnbjhAD96WWxmUAUK4sT_jcfnlcbIHKTSWi5CyGTKgkxJZXyVA" },
  ],
  Salami: [
    { "puuid": "Iu2FkjgpAwF87Avu6N06I3Ru4V1vNR1bQvUJRJMfBDGMX8HCs6Q9u7F5lpSh-t3gt1ppcEbteOCoKA" },
  ]
}

export const FSKRoster = ["Togepi", "Xeonerr", "RAFBIN", "ToongE", "Parasite"]

export const FSKAccounts = {
  Togepi: [
    { "puuid": "nNioqu8esVgIkbT7bk6yNAWPCD6sdpYNP72nMYdOSL94dXwEB_DXWB_w2QnEQmr8oUEOqdCwLmP6eg" },
    { "puuid": "kHT7mVwP6e-An4kRjsR7l0en8YOloRW1ebn2NexS1jIzkLaFfnA4191q8PNh7XvNaZouJ3Y_T6tJLg" },
  ],
  Xeonerr: [
    { "puuid": "WKy56iO4KzdQ1caVMISF0080UUp1YcNhmZgkfzrF1AYrmRJVgUzbD7L51wKvPwNPOCH1eW8UDWdYow" },
    { "puuid": "__bGwS7drBSfNiLl0trB_dYTJfkUcWsQYFhZiKAhPJ20Yqje3tP9h3dmVqeJcmatIvI5O-VXIJn-LA" },
  ],
  RAFBIN: [
    { "puuid": "W4ZBfNCcVbTWk-qCCTyEs10oQgzDZKgurDb84hgIQne9br17pwyCvbXsEJZZzIKqbsebX_CsfD28Bg" },
    { "puuid": "E-wx74or6Y-V7R1_GtTYx1WYS-JrGBBaTwokBZ0jca91I5f4jcNtjPeRGVqPd_pX23baFFBXx-yZaQ" },
  ],
  ToongE: [
    { "puuid": "N3bqcHS-qE0fN7aeKqeln-lkq0ngJaLKKSoU1rltImUQnjxki-lEsZWqMDItcb9Vo_Gpc_sm1GuAxw" },
  ],
  Parasite: [
    { "puuid": "2nbRbTXI1LQ6Yhu56agVLD668O0c9keFBmaSPxRPHs8RBw-9F60bOLdi922XFs1zxuY3A34q0SpPoQ" },
  ]
}

export const EIQRoster = ["Robocop", "SPOOKY", "many", "Strode", "denyk", "behave"]

export const EIQAccounts = {
  Robocop: [
    { "puuid": "3Hvk37SOCq7aI8-alm0c50oBYoXoDfMhuvmI905XaZySD3i4fzEfcnbkUMCgjGXqSxGTU4IacED2Aw" },
    { "puuid": "Eq8yG3Hn9lh7SL0QX1vQZumqD_1vZpmEFq9oSuhIpX3t0GPvJ7b5ExkDl5hvHzlA9NNFj1SUDtRWKA" },
  ],
  SPOOKY: [
    { "puuid": "Bo563PLbzMV_NvmKepv-4_YS7B2wUZcryYQE8GtRBL2DtAmzwMhSY6yLeutOHy1U9QFsJfVKMjClBQ" },
    { "puuid": "OOQmht0wTO_Rt1C4UR_TtIftqyHY6i7MglyUQHNMAExB_kH8LETEd8S9BTQwzOGrXee3HRjeFPtlkA" },
  ],
  many: [
    { "puuid": "LCEWomewJ66NZuHd9hRPHA1U87bpgqlpqu3FaGgOwqa5_-xxOWf1YyyILZlXPQk6W144IbkMKXU2mQ" },
    { "puuid": "Eu1ofwCATt00Z_EKq5FAG-So0G3CI8nenAXcKanUOpmZAIk_uAE1dxQVojnWPTgOiUgE87pY1pzCuA" },
    { "puuid": "iriOLDEDj9zV9DbrtMebtLxryZi9rvQ73zowXKaaAoE1SPAF0XH3-43ET-YLYWuiBgUHTc49GV7ECA" },
  ],
  Strode: [
    { "puuid": "jirISowQwfsU-qq-EPvxhpiXusOr46w8e4jBbYtXBYaD0oqSzC3EhPSIS9ugvwS2WcnONHPAO8sJRg" },
    { "puuid": "3DukXLvvXr4K4jz9mW4aw2myrUgM5Ah8T4AzK6uS8xdX16Jxxk3awseoPjwQBA0xaWTBeDBGUiFrnA" },
    { "puuid": "0QQ4kl2uF9cVNylm5Ll16a-3nRLOpgGH0Mgv8j7zeun233VD9Kq-8lpu8rgMxnJqsoIdoZU-VN4jqA" },
    { "puuid": "JFydtqKs-zBpXWAd2m-oEDfRSSOoeoqWcyGus8PpsnT9tsvLUittjMQCsgWe9lONj6SEpa-5rJfDOA" },
    { "puuid": "IIT8FYXlD65eCY_jiXzD8yv0aVolH1Iz0JbG0sRNg9GnRzgdxbde97XyOgrc5AU9TVjynsUnTOme9A" },
  ],
  denyk: [
    { "puuid": "Zb7-nIGxON76CHzInmh9yB1L1yKIGAT5qjqAJ2laewKxQ0s8YnBsMTYJ7UCV2AzJklx1qO1AceDLHA" },
    { "puuid": "0Ir8xSRUSjDL_XZrwSYxO6B2iuRxQG6DwKu8yu8goA9MRWq3-3LCxreKcMw8SilY-ykudeYijaznnw" },
    { "puuid": "ang-gSF2KYFEDufwZ5Wp7FQr9r_JTPSAiytKzeB2U7OtWSUjzwQF2AOAT711MN0J9JyUDubUf1bbXg" },
  ],
  behave: [
    { "puuid": "LdYexIMctXUR4zl8AU4KyPczF0VRwpsWSxblyLlySDbujR1ZAt2BAkNjlcX24_isYuS0N0sbLEHwRA" },
    { "puuid": "bfcJqp0Gtju9QYgmzDtengW7VJnLp8YiKYXb5GbXZeqPpwCjNmTF25KkVmrXde5jo88qe-ssUYuPmQ" },
    { "puuid": "ZdLqjh3f1aTNvumUeV17RBwD7PJexeSrC7u54bn04WN1OQ2fJsd-EtC6WXGxF4fXDd-G0hfXSi-yKA" },
    { "puuid": "h4Rr9z9IxMnLZaNmf4fwg6XQT05_qftaEIsEYxMnl2jDvxfXkqAUb9GBlZ4g-trmaA2bvOjnE70IUA" },
  ],
}

export const GLRRoster = ["Kulvas", "Ilgar", "FENZ1", "PiOk", "Smarty", "StarScreen", "Astraios", "TIMR", "Ive"]

export const GLRAccounts = {
  Kulvas: [
    { "puuid": "_CNs2wZljRpSTtz6WWE14TMgc2qVViKSrP1KDZG7-_rDfGTb-_9hnMOnpbXMf1HY7Fhj4ts_fGucDg" },
    { "puuid": "HuM6dLyUkg9oXTqmVl-_AuUtbZ1KPCVlDab0kijNDYxkymYkeDFoB0UksGAu09OfFs70y8le7MdOKQ" },
  ],
  Ilgar: [
    { "puuid": "pNiwviNbUUx-Iq02r3pGS0RK5iLEYCjtJAynLdUegI1xODuB9Euq9zPh7zSh4ce788TOfi7JggfR1A" },
    { "puuid": "8vzjtfQTjcKkr4jgpJnFnwG7CMM9JoJLS6PqWw3ibOHZVnwwf63RlCTzsT2VRIiFrgaG8QQLHt7olw" },
  ],
  FENZ1: [
    { "puuid": "YMNiZDFLZpRZRr4JOuS5W7ZCpOcrorBV-9Cql9zKdjwklEVD__yquqnvCKJf7vzEmg4QR3qwcPg4eA" },
    { "puuid": "11VmWMJrAXGUq4gcI6P-jE6Tdp3f-NUdbXRophLHzMeRB2cEUlXVQtSaKcAkF7l2GWj6lVOe6zOtVA" },
    { "puuid": "JaSu7LMSuSnDSLLPg3O99lBSHK3EGbVMPa6lR6gO9PI3bwY78PoNO2x5qR7piTo56N7od47fBJ_34g" },
  ],
  PiOk: [
    { "puuid": "Kts1CbeaU88WNUk4-EKImIpt40KCNF8TO4tzCtFQS71HN4WvSLv_v8_2z7j6dcJtznHwMr9ASg7xnw" },
    { "puuid": "Q1Qt9Mca4_4sAIShdODnNkadZNKyunCKcsQEHb-ZE8jIMfG3FxfVniRtmQLTFCyrBGUj5PH-AIrGBA" },
    { "puuid": "_eZKHxZ6EovwNDjo1m0nSUbVe0OO-_chwXSTXh1AWPjgDGydBzNFfduy7fU32qiZMGQ36gbtDt0zWQ" },
    { "puuid": "ORy_wucx2FWMwVMJFg-4imd_SyVRCwOdOrEzPs9WDJma3J3JayK4ZsAsJJgmaVT6RDhVGbWKD5rSzg" },
  ],
  Smarty: [
    { "puuid": "qHSee1iGSxVQ19KdriO_hT8Yg7NMmmwPMJ4H7BaBd081YKk3mtY65dysa6q40y0FC_3PKYelwTKQLQ" },
    { "puuid": "QFInzrwlldyYwtgds-14zMDqKFAOg94Rhz3F9FMvmw-PCUQ3oKP4-J9FbZHVBt6SNkj-rGX7off5cw" },
    { "puuid": "XqeYIlGe2MRD4xUGOGhnzSQWyUCbc7yZhLk3Ja7AVPH8pdjVeVaTW0VGlMwbU47OaRyfbN4At7FvYg" },
    { "puuid": "T_l3wDqebNdf-NI64ZXPJytRUepyoXbzyueEhFzLNHRrBy5j-VL9rcfDy3vGrVj6fPsw-fX_Guv6qQ" },
    { "puuid": "AEBBsSQKsiZlWeazkIa0ImA-8Rzsuzo_b7dsUIeRby98ieZ3TjTgGoPwlaNXoBFdfykahJaUoam-rQ" },
  ],
  StarScreen: [
    { "puuid": "DvCGSl9r-fZVeevzk-pu2i3gO5ekf8csj7moQBby5JJG8LZ3X9yPp47vwzd9adBfJPoniNpsSTFVtw" },
    { "puuid": "DSxNYPssAssjGgxmbD_jiWgwETUuS4AYdISDWzMODYOzcc40aGNXGcHLLCIBwNEI1AmDvzP1F_ECfA" },
    { "puuid": "4pz7Bg75PwtAT7hWiaibMfPK-o_4aHO8UehktXC05VoGbnqMxYr_cUUJq3Q_yeDXhqOS_DTX7bYhNQ" },
    { "puuid": "rezKPLnbCFLapL1dZqwUaIrEKg0t1IMVwdapN659rgWozK4JHCPVmtuObJ8jH2wLav6_okDTXBBGQQ" },
    { "puuid": "1s4trerX04wm0tTv42CTJe68cQXl1HfaLqkqv2wzu1F48JmL4skb12AS1SD5HESq-mJMSg8D0YtVPQ" },
  ],
  Astraios: [
    { "puuid": "04tyU6U4q8iQktdlrbDEcw8DbZh4O5wzmWhc15KR2dRp4gGQMlg5qjIQg7VlEddXaJhMnY_FQlw6_Q" },
    { "puuid": "3Bq590Oq5Z6DbJ8yATHXLn8FR9KIHuJpyB8rM18hIiz8FQuKHi4ImBKY5LFZ_3O467EbRX_hbWmmdQ" },
    { "puuid": "gJOovJl3M8b3FG462u4qbVLlls1jjV6JYPoYLfL4mI2k8Nh-Eiw6yuLTnaBtl8N_nFxFweWLlPMkaA" },
    { "puuid": "zJDYRN4w6lgkf0nB0HGSX7bga9Js2QQYqFDKpG-8Uxk8Js_Jb5Cyr_4S02yBV-z4EzFSTucYEwYvSA" },
  ],
  TIMR: [
    { "puuid": "eHGoxiRAFNluoioORKb7GvZR-S9ykDhbIPWqriU2I1-3H4_Jy35qWv7-owqD8OIwv9a_tSbAqvT5MQ" },
    { "puuid": "ci_rSmQU3Zp57NBEFTL1SRQmOmPYH-I4-1Lis7rEQ6y1SemytLtV_Panfwv5mu_e69dAfnTZpaz5tA" },
    { "puuid": "b1OlTQ_lDhgGOD7V37nYlVLv2Y7NO6OlcPs-ibtrDNjit7919ckjx6AMzNo5gluUouVFSj_seyrZhA" },
    { "puuid": "K6uuNxOgvFiP7wG6ghiGTXK5cZXfQcdLFBvYxzrDQrbDvImGQQSZk0lpZllV-18yAiLyLAG3aIzAUQ" },
    { "puuid": "Mqhp67JhtpVZUlI47CFDXI_NjKvx2vPAdsTSbk4ZJKSOybZUrZxXJ6LW2EJZEACYxYzASzRH_HSi9Q" },
  ],
  Ive: [
    { "puuid": "yZ1xG77cm7kmGOvwUbu8VQEgt8NUMX_G0H4HHmR8QC4RIddFiS5gqj7Y0YOTGLL7eKdVBWQiXhu6cQ" },
  ]
}

export const UNITRoster = ["Wind1", "Pshybyl", "Defflok", "KNEZA", "J3rkie", "Youdaaa", "Freestyle"]

export const UNITAccounts = {
  Wind1: [
    { "puuid": "wcOq2-62MS9573GBEfaVyBcXe5WiCnvdr15SX_RZLJoPuVuqZMZ3FMqsSKZoeNMNUUskkpbiye4MwQ" },
    { "puuid": "BZYTIAPJoMLponKwfvRNujvf0ql5UHkzmO-WHZS80NQP-s6W4SEl2aLuayx9_FRGNtQzxehiW5jqUw" },
    { "puuid": "fwL3FI7X6BdKa7g5jAFeI8_f0nrl801JZNCaOX6pBmdXvLvxv8RdTjXnpQ75yZ7aFvqgwfGqZ8OhMQ" },
  ],
  Pshybyl: [
    { "puuid": "kYUCjUMdMj53x1dVS0tNt8Ii_gmb0Td6tWnkFWzpRgVeRdF8I7xpy4g9sjhodsu25PTUSKJqx8CmGw" },
    { "puuid": "pGUW73nWU4c3DnIkV9lGsX9_JrmPK6cz5eU07g3obyJaeh_bQywY5o6NUXqIV55-bVJqSy9jgVvOUw" },
  ],
  Defflok: [
    { "puuid": "1tG3lMJ3hWA0jWFYCVSd__m3EUTnLyVr3NmJkauwd5GU9DpCNonCcggeTsBuOVzV6bhpDkIupEuK7w" },
    { "puuid": "14J0GjzMx-NIEISFH-ZnQjIrU1RvLpPnqxdlZjqQTa_aj3JXIIog55aCha5qa9ea2KfGQD7VJg5Xnw" },
    { "puuid": "I6tqcuD6KpVuYCfRY8sp3Qrft4D2D8gTyRGW_wcw0A3sshO3gdY4gj9sCUT_dm4JMYkEI_hWWZBboA" },
  ],
  KNEZA: [
    { "puuid": "ev0_cNr0Wi22QR6359mRUJaIJwRcFpPULCFKlht6u1p_cO3n56f5pqxONXJHz_tv828RI43eFm49tw" },
    { "puuid": "GF-oliByNq8hQNL1BAt5Yf5ttjo5B1t_HjR_lxNzKp3HlbGdEqIVscioY1qdXTDKz-FUsXoS_6vPKA" },
    { "puuid": "Fet3-QQV4Ka0evzSG3kJzau3hSi3qHhH_PTh1onz1Y9oOtgFiBq4AEHdSR3AqQAAkLMtcwoJDjgsFQ" },
    { "puuid": "1pHPR2KhKNF0EHYi6y_jhQ_PjIed5MEr57nVIx3WNp1yv-oQYB4joGwCTbI2bVe-9YE7t_pUylFICA" },
  ],
  J3rkie: [
    { "puuid": "oV-dzgBGoICQGsAkD3n8qHSECRv-6b5RjkY4yI8wnVjaVeu8c4J-UzOEfg4RNZyZbtQKAzb2MD8YxA" },
  ],
  Youdaaa: [
    { "puuid": "BvPLimHh3ceEVTQWgXDO2pKwEsoI5eNoKBck-EBBxFjmgARqsfJCjnOeD6BBMnrtgNb7xNOOmpEOYQ" },
  ],
  Freestyle: [
    { "puuid": "SSDhW1KvHYl10_ERCYACpe_Td1IoLRyEZUbVgcQXgqgh5qk-igEPh27-rq2htUYO3cyVas9bMtXlgg" },
  ]
}

export const Z10Roster = ["Melonik", "bluerzor", "Kofte", "HARPOON", "Erdote", "Puki Style", "Ariana"]

export const Z10Accounts = {
  Melonik: [
    { "puuid": "TlGHGt2SRaUJqbWlr4LwO6YsGDCw1AzwDaZI5xKGEaswvgBLF3y1WFMPH_0tM1XExCMhjHX33Z_7kQ" },
    { "puuid": "xeCPF5LEhwN0bgFDE-IBif4h3Yj0-o_C8-R16tJbHeekJDYBxkIcLqGCDE8Uh4jWH2G0BIGNwy52Zg" },
  ],
  bluerzor: [
    { "puuid": "AxNi4syskA4yo6DjLJOqah8jpJIm3VD0P_dKpsjuDusEQkluaC_-AUxaTq0SHQjunzTxvMZwSjPlTA" },
    { "puuid": "8Bj1Kjt0BA8AJimVq9BMrxG3i8CKyUut6qHSUvr0MFqYSxhhJHjO6VrWXzNcOEgCSOmb5ueVN77Qlg" },
    { "puuid": "YLKX-NhioZj-WidTKVJUPke8zRm-nk-bkv8Va07RNuuTEJfpL31VEX_03KeQ3n0yh4icjQYDw-SSRg" },
  ],
  Kofte: [
    { "puuid": "2BmzirH-O4vrWdhGz9sQjDfg2YFsZUBUsQEhr84kFeh-cq_VM9d2ekADH1cjMPBzyETLaaJiFloKuw" },
    { "puuid": "hy31YE1CKutUIdX1LpDIDy7dl5cxgUcc6UyDpZCViPh-HO0DhtKCkUSq3C3uvuu6hi9lDPWtFvZPwA" },
    { "puuid": "Fo77UmRRtDoaoCdd4mDkBBtehfDNOjutD7VhlGJEoevD5Q_qxwlxqDrLFPqghEnw07ZGxcgrhJ10Ow" },
    { "puuid": "N9ZUT3G0wD7PAv0e0blzzcv-F_gXk3ro3MbW1nL2Dgv5lm4deqjxmyKTb8Au1xV003K8QVXNs2LRrg" },
  ],
  HARPOON: [
    { "puuid": "D39ZNnU0Wu-lVEFJpBysKN0ZlKvZwrMuOkuranYtWHzFvoGSmaPqg9N7AJkm8-W75Jh0CjrUX4rO6Q" },
  ],
  Erdote: [
    { "puuid": "SJ5tI4NvyqEAQ_nzKYnOq05WYh9jkn9nEIY-MoTNIMY6BSXEmYZ7glsn7L_CFRzMj8-A9NYWIjkTOQ" },
    { "puuid": "z1M1eo3Vi0oRoqSytlgrDKIk_0q3mXQBwXv0G62D6O3h1bQKt9sveSG_8zgzEkQE_zttyjqISjZgdA" },
    { "puuid": "LxMnBO0UHwcoXMae7kSllcGtILwJuFRLNN1lSeeY3IYCqhHLa6_zbV-iK5F12J-VobZQkb_cnBzuYw" },
    { "puuid": "c3Plwi4dnl-KhASmzoMhA0P7nIAeZAuf6tA78quglx71WHP0BxS2GRBvGudH9BReNZJ-uDUm3Qm7XQ" },
    { "puuid": "GIzjv12lZF6Z6vnqLdZDQT2fYbPh7Na2dHF-8aAfnUdAhxC0vJYeEgySXjJbjeq2mEKwaj94zMxrAw" },
  ],
  "Puki Style": [
    { "puuid": "4186hDzrRgTrnS-XXhmVzT9rxolRZRjefTgvvJaPhjNC7a7vUOWVp0Moy89mVArLm2vaEsl5ubw3VA" },
    { "puuid": "WS2bHNpRuwpbEew0PM5NEaOn0WqQq6Sb6ext3ZscOtoF0At4lRxvvDKACaqBCqS3uGUmqhDY0mcFfg" },
    { "puuid": "-WqyUuVT6Vv3s0xiukez1QaxSrKH6ppW_pjmIo_vvJK-8LKmgMLaR_BGlYvcSFUQ6gSn_h3WbT0FuQ" },
    { "puuid": "zuCQ0rdNZ1qKFEn6iGaUQ_oxmNPstbUbHlQIozhh5Q4uVUfaVUb_I677HDPSmBJqVG7c2SCjH-2frQ" },
    { "puuid": "rinzoLMYoz4vfHAT2DWiqnMAqoJVor5lYvXKiGcdKRNF_rbi5yrFfAEhglarQSFLhm2BnB5KxFY_5w" },
  ],
  Ariana: [
    { "puuid": "kBS1uJsW5iuW6Jlq9mNUr90_69U0XzZnV5UmvulgZlClr-Vd6AD1LDFQAxHJkhL0_JnGSvyFY7Btuw" },
  ]
}

