import patchData from '../../assets/data/patchDates.json';

interface Props {
  itemId: number;
  size?: number;
  matchDate?: number;
}

const ItemImage = ({ itemId, size = 25, matchDate }: Props) => {
  const parsedMatchDate = matchDate && matchDate.toString().length >= 11
    ? Math.round(matchDate / 1000)
    : matchDate
  const patch = (matchDate && parsedMatchDate)
    ? patchData.find((patch) => {
      return (
        patch.PatchStartEpoch <= parsedMatchDate &&
        patch.PatchEndEpoch >= parsedMatchDate
      );
    })?.RiotPatchName
    : patchData.find((patch) => {
      const now = new Date();
      const nowEpoch = now.getUTCSeconds();
      return (
        patch.PatchStartEpoch <= nowEpoch && patch.PatchEndEpoch >= nowEpoch
      );
    })?.RiotPatchName

  const imgUrl = `https://ddragon.leagueoflegends.com/cdn/${patch}/img/item/${itemId}.png`;

  return itemId ? (
    <img height={size} width={size} src={`${encodeURI(imgUrl)}`} alt='' />
  ) : (
    <></>
  );
};

export default ItemImage;
