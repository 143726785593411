import LOGLogo from '../../assets/images/LeagueOfGraphs.png';

interface Props {
  query: string;
  type: 'player' | 'match';
  size?: number;
}

export const LOGImageLink = ({query, size = 50, type}: Props) => {
  const matchLink = `https://www.leagueofgraphs.com/match/euw/${encodeURI(
    query.split('EUW1_')[1]
  )}`;
  const summonerLink = `https://www.leagueofgraphs.com/summoner/euw/${encodeURI(
    query
  )}`;
  const link = type === 'player' ? summonerLink : matchLink;
  const onClick = () => {
    return window.open(link, '_blank');
  };
  return (
    <img
      height={size}
      width={size}
      src={LOGLogo}
      alt={link}
      onClick={() => onClick()}
      style={{cursor: 'pointer'}}
    />
  );
};
