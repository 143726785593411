/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmpty } from "lodash"
import { useContext, useEffect, useState } from "react"
import { Box, Card, Container, Paper, Typography, Unstable_Grid2 as Grid } from "@mui/material"

import { getBGZRoflScrimList } from "../../../services/api/atlas"
import CollapsableCard from "../../../components/common/CollapsableCard"
import { ROFLSummaryList, Participant } from "../../../types/api/atlas/ROFL Summary"
import { ROFLMatchDetails } from "../../../components/ROFLMatchDetails"
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts"
import { LoadingContext } from "../../../context/LoadingContext"

export const BGZRoflScrimsDashboard = () => {

  const [scrimList, setScrimList] = useState<ROFLSummaryList>([])
  const [scrimStats, setScrimStats] = useState<ScrimsStats>()

  const { setIsLoading } = useContext(LoadingContext);


  useEffect(() => {
    setIsLoading(true)
    getBGZRoflScrimList().then((data) => {
      setScrimList(data)
      setIsLoading(false)
    })
  }, [])

  interface Vs {
    champion: string
    winrate: number
    kda: number
  }
  interface Matchups {
    champion: string
    vs: Vs[]
  }
  interface General {
    kda: number
    kp: number
  }
  interface PlayerStats {
    general: General
    matchups: Matchups[]
  }
  interface ScrimsStats {
    "BGZ Alvke": PlayerStats
    "BGZ Prominence": PlayerStats
    "BGZ Ryoh": PlayerStats
    "BGZ Sh3ry": PlayerStats
    "BGZ Eskiper": PlayerStats
  }

  useEffect(() => {
    const parsedStats: ScrimsStats = {
      "BGZ Alvke": {
        general: {
          kda: 0,
          kp: 0
        },
        matchups: [{
          champion: "",
          vs: [
            {
              champion: "",
              winrate: 0,
              kda: 0,
            }
          ]
        }]
      },
      "BGZ Prominence": {
        general: {
          kda: 0,
          kp: 0
        },
        matchups: [{
          champion: "",
          vs: [
            {
              champion: "",
              winrate: 0,
              kda: 0,
            }
          ]
        }]
      },
      "BGZ Ryoh": {
        general: {
          kda: 0,
          kp: 0
        },
        matchups: [{
          champion: "",
          vs: [
            {
              champion: "",
              winrate: 0,
              kda: 0,
            }
          ]
        }]
      },
      "BGZ Sh3ry": {
        general: {
          kda: 0,
          kp: 0
        },
        matchups: [{
          champion: "",
          vs: [
            {
              champion: "",
              winrate: 0,
              kda: 0,
            }
          ]
        }]
      },
      "BGZ Eskiper": {
        general: {
          kda: 0,
          kp: 0
        },
        matchups: [{
          champion: "",
          vs: [
            {
              champion: "",
              winrate: 0,
              kda: 0,
            }
          ]
        }]
      }
    }

    const a = scrimList.map((scrim) => {
      const gameStats = {
        kills: 0,
        deaths: 0,
        assists: 0
      }

      const allies = scrim.statsJson.filter((player) => player.name.includes("BGZ"))
      const enemies = scrim.statsJson.filter((player) => !player.name.includes("BGZ"))
      const matchups = allies.map((ally) => {
        const enemyLaner = enemies.find((enemy) => enemy.playerRole === ally.playerRole)
        return {
          ally: ally,
          enemy: enemyLaner as Participant
        }
      })
      const auxMatchups = {}
      const yajoo = matchups.map((matchup) => {
        const ally = matchup.ally
        const enemy = matchup.enemy
      })

      return matchups
    })
  }, [scrimList])

  //100
  const bsGames = scrimList.filter((scrim) => {
    return scrim.statsJson.find((participant) => {
      return participant.name.includes('BGZ') && Number(participant.team) === 100
    })
  })

  const bsWins = bsGames.filter((filteredScrim) => {
    return filteredScrim.statsJson.find((participant) => {
      return participant.name.includes('BGZ') && participant.win === 'Win'
    })
  })

  //200
  const rsGames = scrimList.filter((scrim) => {
    return scrim.statsJson.find((participant) => {
      return participant.name.includes('BGZ') && Number(participant.team) === 200
    })
  })

  const rsWins = rsGames.filter((filteredScrim) => {
    return filteredScrim.statsJson.find((participant) => {
      return participant.name.includes('BGZ') && participant.win === 'Win'
    })
  })

  const gameStats = {
    blueSideGames: bsGames.length,
    blueSideGamesWon: bsWins.length,
    redSideGames: rsGames.length,
    redSideGamesWon: rsWins.length,
    combinedGames: bsGames.length + rsGames.length,
    combinedWins: bsWins.length + rsWins.length,
  }

  const parsedBSStats = [
    {
      'name': 'Blue side Wins',
      'value': gameStats.blueSideGamesWon
    },
    {
      'name': 'Blue side Defeats',
      'value': gameStats.blueSideGames - gameStats.blueSideGamesWon
    },
  ]

  const parsedRSStats = [
    {
      'name': 'Red side Wins',
      'value': gameStats.redSideGamesWon
    },
    {
      'name': 'Red side Defeats',
      'value': gameStats.redSideGames - gameStats.redSideGamesWon
    },
  ]

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number, name: string, value: number }) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, name, value } = props
    const radius = innerRadius + (outerRadius - innerRadius) * 0.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}% ${name.split(' ')[2]} (${value})`}
      </text>
    );
  };

  return (
    <Container>
      <Paper>
        <Card>
          <Box sx={{ margin: '16px' }}>
            <Box>
              <Typography variant="h5" color={"white"}>
                Scrims Stats ({scrimList.length} scrims tracked)
              </Typography>
            </Box>
            <Grid container>
              <Grid xs={6} sx={{ paddingRight: '16px', backgroundColor: '#202a3e' }}>
                <ResponsiveContainer width="100%" minHeight="300px">
                  <>
                    <Typography>
                      Blue Side ({gameStats.blueSideGames})
                    </Typography>
                    <PieChart width={250} height={250}>
                      <Pie data={parsedBSStats} labelLine={false} label={renderCustomizedLabel} dataKey="value" cx="50%" cy="50%">
                        {parsedBSStats.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.name.includes('Win') ? '#246840' : '#660A10'} />
                        ))}
                      </Pie>
                    </PieChart>
                  </>
                </ResponsiveContainer>
              </Grid>
              <Grid xs={6} sx={{ paddingLeft: '16px', backgroundColor: '#472a2f' }}>
                <ResponsiveContainer width="100%" minHeight="300px">
                  <>
                    <Typography>
                      Red Side ({gameStats.redSideGames})
                    </Typography>
                    <PieChart width={250} height={250}>
                      <Pie data={parsedRSStats} labelLine={false} label={renderCustomizedLabel} dataKey="value" cx="50%" cy="50%">
                        {parsedRSStats.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.name.includes('Win') ? '#246840' : '#660A10'} />
                        ))}
                      </Pie>
                    </PieChart>
                  </>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </Box>
        </Card>
        {!isEmpty(scrimList) && (
          <CollapsableCard
            defaultExpanded={false}
            keyword="Scrims"
            title={'Scrim List'}
          >
            <>
              {scrimList.map((scrim, i) => {
                const ownTeamId = scrim.statsJson.find((participant) => {
                  return participant.name.includes('BGZ')
                })?.team
                const isWin = scrim.gameWinner.toString() === ownTeamId
                const copyGameId = () => {
                  navigator.clipboard.writeText(`${scrim.fileName.split('.')[0]}`)
                }
                return (
                  <CollapsableCard
                    defaultExpanded={false}
                    keyword="Scrim"
                    title={
                      (
                        <Box onClick={copyGameId}>{`${new Date(scrim.gameDate * 1000).toLocaleDateString()} - ${scrim.gameVersion.slice(0, 5)} - ${scrim.fileName.split('.')[0]}`}
                        </Box>
                      )
                    }
                    key={i}
                    color={isWin ? '#202a3e' : '#472a2f'}
                  >
                    <ROFLMatchDetails match={scrim} teamCode={"BGZ"} />
                  </CollapsableCard>
                );
              })}
            </>
          </CollapsableCard>
        )
        }
      </Paper>
    </Container >
  )
} 