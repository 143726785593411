import { Box, Button, Card, CardHeader, FormControl, InputLabel, MenuItem, Pagination, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";

import CollapsableCard from "./common/CollapsableCard";
import { MatchDetails } from "./MatchDetails";

import { getAccountByRiotId, getMatchList, getSummonerByPuuid } from "../services/api/riot";

import { Summoner } from "../types/api/riot/summoner";
import { RiotAccount } from "../types/api/riot/accounts";
import { LoadingContext } from "../context/LoadingContext";

export const LolSummoner = () => {
  type DataType = 'PUUID' | 'RiotID'
  const [dataType, setDataType] = useState<DataType>('' as DataType)
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [matchList, setMatchList] = useState<string[]>();
  const [selectedQuery, setSelectedQuery] = useState<string>('');
  const [selectedPlayerInfo, setSelectedPlayerInfo] = useState<Summoner | RiotAccount>(
    {} as Summoner
  );
  const { setIsLoading } = useContext(LoadingContext);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedQuery(event.target.value);
  }

  const handleTypeChange = (event: SelectChangeEvent) => {
    setDataType(event.target.value as DataType);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    const start = value - 1
    setIsLoading(true)
    getMatchList(selectedPlayerInfo.puuid, undefined, start, '0')
      .then((matchListData) => {
        if (!isEmpty(matchListData)) {
          setCurrentPage(value);
          setMatchList(matchListData);
        }
      }).finally(() => {
        setIsLoading(false)
      });
  };

  const handleSummonerSearchClick = () => {
    setMatchList([]);
    setCurrentPage(1);
    setIsLoading(true)
    if (dataType === 'PUUID') {
      getSummonerByPuuid(selectedQuery as string)
        .then((data) => {
          setSelectedPlayerInfo(data);
        }).finally(() => {
          setIsLoading(false)
        });
    }
    if (dataType === 'RiotID') {
      getAccountByRiotId(selectedQuery.split('#')[0] as string, selectedQuery.split('#')[1] as string)
        .then((data) => {
          setSelectedPlayerInfo(data);
        }).finally(() => {
          setIsLoading(false)
        });
    }
  };

  const getPlayerName = () => {
    if ((selectedPlayerInfo as Summoner).name) {
      return (selectedPlayerInfo as Summoner).name
    }
    if ((selectedPlayerInfo as RiotAccount).gameName) {
      return `${(selectedPlayerInfo as RiotAccount).gameName}#${(selectedPlayerInfo as RiotAccount).tagLine}`
    }
  }

  useEffect(() => {
    if (!isEmpty(selectedPlayerInfo)) {
      setIsLoading(true)
      getMatchList(selectedPlayerInfo.puuid, undefined, 0, '0')
        .then((matchListData) => {
          setMatchList(matchListData);
        }).finally(() => {
          setIsLoading(false)
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayerInfo])

  return (
    <Box>
      <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
        <InputLabel>Data Type</InputLabel>
        <Select
          value={dataType}
          label="Data Type"
          onChange={handleTypeChange}
          sx={{ minWidth: 150 }}
        >
          <MenuItem value={'PUUID'}>PUUID</MenuItem>
          <MenuItem value={'RiotID'}>RiotID</MenuItem>
        </Select>
        <TextField id="outlined-basic" label='Query' variant="outlined" onChange={handleNameChange} />
        <Button onClick={handleSummonerSearchClick} variant='outlined'
          sx={{ marginLeft: '8px' }}
        >
          <Typography>
            Search
          </Typography>
        </Button>
      </FormControl>
      {
        !isEmpty(matchList) && matchList && !isEmpty(selectedPlayerInfo) && (
          <Card style={{ textAlign: 'center' }}>
            <CardHeader title={getPlayerName()}></CardHeader>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {matchList.map((match, i) => {
                return (
                  <CollapsableCard
                    defaultExpanded={false}
                    title={match}
                    query={match}
                    key={i}
                  >
                    <MatchDetails match={match} />
                  </CollapsableCard>
                );
              })}
              <Pagination
                color='primary'
                count={10}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Box>
          </Card>
        )
      }
    </Box >
  )
}